import { LOGIN, TorusKey } from "@toruslabs/customauth";
import { isMatch, pickBy } from "lodash-es";
import { Payload } from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { setAuthToken } from "@/apollo";
import { getUserInfo, updateUserInfo, verifyMessageAndRegisterUser } from "@/graphql/mutations";
import { fetchUserInfoQuery } from "@/graphql/operations/__generated__/user";
import { Device, UpdateUserPayload, User, UserDapp } from "@/utils/__generated__/graphql-types";
import { SESSION_STORAGE_KEY, USER_MODULE_KEY } from "@/utils/enums";
import { ErrorTraceObj, ToastMessage, TorusUserInfo } from "@/utils/interfaces";
import { canPreserveState } from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";
import { SkipSync } from "../skipSync";

@Module({
  namespaced: true,
  name: USER_MODULE_KEY,
  store,
  dynamic: true,
  preserveState: canPreserveState(USER_MODULE_KEY, SESSION_STORAGE_KEY),
})
class UserModule extends VuexModule {
  @SkipSync(USER_MODULE_KEY)
  public toastMessage: ToastMessage = {
    message: "",
  };

  // @SkipSync(USER_MODULE_KEY)
  public errorTraceMessageList: ErrorTraceObj[] = [];

  public userInfo: TorusUserInfo = {
    email: "",
    name: "",
    profileImage: "",
    verifier: "",
    aggregateVerifier: "",
    verifierId: "",
    typeOfLogin: LOGIN.GOOGLE,
  };

  public clientTimeOffset = 0;

  public keyInfo: TorusKey = {
    typeOfUser: "v1",
    privateKey: "",
    pubKey: {
      pub_key_X: "",
      pub_key_Y: "",
    },
    publicAddress: "",
    metadataNonce: "",
  };

  public walletKeyInfo: TorusKey = {
    typeOfUser: "v1",
    privateKey: "",
    pubKey: {
      pub_key_X: "",
      pub_key_Y: "",
    },
    publicAddress: "",
    metadataNonce: "",
  };

  public currentDappClientId = "";

  public authToken = "";

  public challenge = "";

  public uniqueDapps = [];

  public alwaysSkip = false;

  public persistedUserInfo: Partial<fetchUserInfoQuery["info"]> = {};

  @Mutation
  public setClientTimeOffset(offset: number) {
    this.clientTimeOffset = offset;
  }

  @Mutation
  public setKeyInfo(value: TorusKey): void {
    this.keyInfo = value;
  }

  @Mutation
  public setWalletKeyInfo(value: TorusKey): void {
    this.walletKeyInfo = value;
  }

  @Mutation
  public setUserInfo(value: TorusUserInfo): void {
    this.userInfo = value;
  }

  @Mutation
  public setCurrentClientId(value: string) {
    this.currentDappClientId = value;
  }

  @Mutation
  public setAuthToken(value: string) {
    this.authToken = value;
    setAuthToken(value);
  }

  @Mutation
  public setChallenge(value: string) {
    this.challenge = value;
  }

  @Mutation
  public resetCurrentClientId(value: string) {
    if (value === this.currentDappClientId) this.currentDappClientId = "";
  }

  @Mutation
  public setToastMessage(details: ToastMessage) {
    this.toastMessage = details;
  }

  @Mutation
  public setAlwaysSkip(value: boolean) {
    this.alwaysSkip = value;
  }

  @Mutation
  public setPersistedUserInfo(info: Partial<fetchUserInfoQuery["info"]>) {
    this.persistedUserInfo = { ...this.persistedUserInfo, ...info };
  }

  @Mutation
  public logout() {
    this.userInfo = {
      email: "",
      name: "",
      profileImage: "",
      verifier: "",
      aggregateVerifier: "",
      verifierId: "",
      typeOfLogin: LOGIN.GOOGLE,
    };
    this.keyInfo = {
      typeOfUser: "v1",
      privateKey: "",
      pubKey: {
        pub_key_X: "",
        pub_key_Y: "",
      },
      publicAddress: "",
      metadataNonce: "",
    };
    this.walletKeyInfo = {
      typeOfUser: "v1",
      privateKey: "",
      pubKey: {
        pub_key_X: "",
        pub_key_Y: "",
      },
      publicAddress: "",
      metadataNonce: "",
    };
    this.currentDappClientId = "";
    this.authToken = "";
    this.persistedUserInfo = {};
    this.challenge = "";
    // this.errorTraceMessageList = [];
  }

  @Mutation
  public setErrorTraceMessages(details: ErrorTraceObj[] | ErrorTraceObj) {
    this.errorTraceMessageList = Array.isArray(details) ? [...this.errorTraceMessageList, ...details] : [...this.errorTraceMessageList, details];
  }

  @Mutation
  public clearErrorTraceMessages() {
    this.errorTraceMessageList = [];
  }

  @Action
  async authenticateUserToken(params: {
    network: string;
    public_address: string;
    private_key: string;
    verifier: string;
    verifier_id: string;
    session_nonce: string;
    device_id: string;
    client_id: string;
    hostname: string;
    user_type: string;
  }): Promise<{
    user: User;
    userDapp: UserDapp;
    device: Device;
    idToken: string;
    clientTimeOffset: number;
    messageForSign: string;
  } | null> {
    const { private_key, device_id, client_id, public_address, verifier, verifier_id, network, session_nonce, hostname, user_type } = params;
    const res = await verifyMessageAndRegisterUser(
      {
        public_address,
        verifier_id,
        session_nonce,
        device_id,
        client_id,
        user_type,
        verifier,
        network,
        hostname,
      },
      private_key
    );
    const { clientTimeOffset, idToken, messageForSign } = res || {};
    if (clientTimeOffset !== undefined) this.setClientTimeOffset(clientTimeOffset);
    if (idToken) {
      this.setAuthToken(idToken);
    }
    if (messageForSign) {
      this.setChallenge(messageForSign);
    }
    return res || null;
  }

  @Action
  async fetchUserInfo(): Promise<fetchUserInfoQuery["info"] | null> {
    if (this.authToken) {
      this.setAuthToken(this.authToken);
      const userDBInfo = await getUserInfo();
      if (userDBInfo) {
        const { always_skip_tkey } = userDBInfo;
        if (always_skip_tkey) this.setAlwaysSkip(always_skip_tkey);
        this.setPersistedUserInfo(userDBInfo);
      }
      return userDBInfo;
    }
    return null;
  }

  @Action
  async updateUserPersistedInfo(params: { payload: UpdateUserPayload; throwError?: boolean }) {
    const { payload, throwError } = params;
    const finalPayload = pickBy(payload, (val) => val !== null && val !== undefined && val !== "");

    const infoAlreadyExist = isMatch(this.persistedUserInfo, finalPayload);
    if (!infoAlreadyExist) {
      try {
        await updateUserInfo(payload);
        this.setPersistedUserInfo({ ...payload });
      } catch (error) {
        if (throwError) throw error;
      }
    }
  }
}

const userModule = getModule(UserModule);

installStorePlugin({
  key: USER_MODULE_KEY,
  storage: SESSION_STORAGE_KEY,
  filter: (mutation: Payload) => {
    if (mutation.type === `${USER_MODULE_KEY}/setToastMessage`) return false;
    return true;
  },
  saveState: (key: string, state: Record<string, unknown>, storage?: Storage) => {
    storage?.setItem(key, JSON.stringify(state));
  },
  restoreState: (key: string, storage?: Storage) => {
    const value = storage?.getItem(key);
    if (typeof value === "string") {
      // If string, parse, or else, just return
      const parsedValue = JSON.parse(value || "{}");
      delete parsedValue[USER_MODULE_KEY]?.toastMessage;
      if (parsedValue[USER_MODULE_KEY]?.authToken) {
        setAuthToken(parsedValue[USER_MODULE_KEY]?.authToken);
      }
      return {
        [USER_MODULE_KEY]: parsedValue[USER_MODULE_KEY],
      };
    }
    return value || {};
  },
});

export default userModule;
