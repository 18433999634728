import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { LOCAL_STORAGE_KEY, PID_MODULE_KEY } from "@/utils/enums";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";

// PID store - stores temporary information about a request/response based on their unique PID
// TODO: Make sure it's mounted first before using methods
@Module({ dynamic: true, namespaced: true, name: PID_MODULE_KEY, store })
class PIDModule extends VuexModule {
  public _pidStore: Record<string, Record<string, unknown>> = {};

  public _timestamps: Record<string, number> = {};

  public timeout = 600000;

  get pidStore() {
    return this._pidStore;
  }

  get timeStamps() {
    return this._timestamps;
  }

  @Mutation
  public cleanup() {
    if (!this) return;
    const tempStore: Record<string, Record<string, unknown>> = {};
    const tempTimestamps: Record<string, number> = {};
    Object.keys(this._pidStore).forEach((key) => {
      if (this._pidStore[key] && this._timestamps[key]) {
        const timestamp = this._timestamps[key];
        if (Date.now() - timestamp < this.timeout) {
          tempStore[key] = this._pidStore[key];
          tempTimestamps[key] = this._timestamps[key];
        }
      }
    });

    this._pidStore = tempStore;
    this._timestamps = tempTimestamps;
  }

  @Mutation
  public _setPID({ pid, data }: { pid: string; data: Record<string, unknown> }): void {
    if (!this) return;
    let existingData: Record<string, unknown> = {};
    if (this._pidStore[pid]) {
      existingData = this._pidStore[pid];
    }
    this._pidStore[pid] = { ...JSON.parse(JSON.stringify(existingData)), ...data };
    this._timestamps[pid] = Date.now();
  }

  @Action
  public setPID({ pid, data }: { pid: string; data: Record<string, unknown> }): void {
    if (!this) return;
    this.cleanup();
    this._setPID({ pid, data });
  }
}

const pidModule = getModule(PIDModule);

installStorePlugin({ key: PID_MODULE_KEY, storage: LOCAL_STORAGE_KEY });

export default pidModule;
