import "./registerServiceWorker";

import VueGtm from "@gtm-support/vue2-gtm";
// import log from "loglevel";
import Vue from "vue";

import { getUserLanguage } from "@/utils/utils";

import App from "./App.vue";
import i18n, { loadLanguageAsync } from "./plugins/i18n-setup";
import vuetify from "./plugins/vuetify";
import router from "./router";
import mixins from "./TranslationMixin";
import { installSentry } from "./utils/sentry";
import store from "./vuexStore";

Vue.config.productionTip = false;

Vue.mixin(mixins);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// log.enableAll();

installSentry(Vue);

loadLanguageAsync(getUserLanguage());

if (["production", "aqua", "cyan"].includes(process.env.VUE_APP_TORUS_BUILD_ENV as string))
  Vue.use(VueGtm, {
    id: "GTM-WQLQN9X", // GTM tag for app.openlogin.com
    defer: true,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
  });
