/* eslint-disable simple-import-sort/imports */
import * as Sentry from "@sentry/vue"; // Disable ESLint import sorting because '@sentry' require 'vue' and 'browser' packages to be imported before 'tracking' package
import { Integrations as BrowserIntegrations } from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import LoglevelSentryPlugin, { redactBreadcrumbData } from "@toruslabs/loglevel-sentry";
import log from "loglevel";
import Vue from "vue";
import { enableSentryTracing } from "@toruslabs/http-helpers";

import config from "@/config";

function getSampleRate(): number {
  try {
    return Number.parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE || "0.2");
  } catch {
    return 0.2;
  }
}

function getTracesSampleRate(): number {
  try {
    const defaultTracingRate = "0.01";
    return Number.parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE || defaultTracingRate);
  } catch {
    return 0.01;
  }
}

export function installSentry(vue: typeof Vue): void {
  if (!process.env.VUE_APP_SENTRY_DSN) return;

  Sentry.init({
    Vue: vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_TORUS_BUILD_ENV,
    release: `openlogin@${process.env.VUE_APP_TORUS_BUILD_VERSION}`,
    integrations: [
      new BrowserIntegrations.Breadcrumbs({ console: false }),
      new BrowserTracing({
        tracingOrigins: [config.apiHost, config.metadataHost, config.openloginBackend],
      }),
    ],
    trackComponents: true,
    ignoreErrors: [
      // Happen when user click 'X' on the browser (ref https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2)
      "TypeError: Failed to fetch", // All except iOS and Firefox
      "TypeError: cancelled", // iOS
      "TypeError: NetworkError when attempting to fetch resource.", // Firefox
    ],
    sampleRate: getSampleRate(),
    tracesSampleRate: getTracesSampleRate(),
    normalizeDepth: 8,
    beforeBreadcrumb(breadcrumb) {
      breadcrumb.data = redactBreadcrumbData(breadcrumb.data);
      return breadcrumb;
    },
  });

  const plugin = new LoglevelSentryPlugin(Sentry);
  plugin.install(log);
  enableSentryTracing(Sentry, [config.apiHost, config.metadataHost, config.openloginBackend], ["/jrpc"]);
}

export function setSentryEnabled(enabled: boolean): void {
  const client = Sentry.getCurrentHub().getClient();
  if (!client) return;

  client.getOptions().enabled = enabled;
}
