
import { Component, Vue } from "vue-property-decorator";
import type { Route } from "vue-router";
import type { Framework } from "vuetify";

import Trace from "@/containers/Trace";

import { initSdk } from "./sdk";
import { dappModulesStoreModule, registerDappModule, userModule } from "./store";
import { setTheme } from "./utils/utils";

@Component({ components: { Trace } })
export default class App extends Vue {
  $route!: Route;

  $vuetify!: Framework;

  get isModal(): boolean {
    return this.$route.name === "SdkModal";
  }

  created(): void {
    const client = userModule.currentDappClientId;
    if (client) {
      registerDappModule(client);
      const currentDappModule = dappModulesStoreModule.dappModules[client];
      setTheme(currentDappModule.whiteLabel, this.$vuetify);
    }
  }

  mounted(): void {
    initSdk();
  }
}
