import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
  },
  {
    path: "/start",
    name: "Start",
    component: () => import(/* webpackChunkName: "start" */ "../views/Start"),
  },
  {
    path: "/callback",
    name: "Callback",
    component: () => import(/* webpackChunkName: "callback" */ "../views/Callback"),
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/RedirectAuth"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "register" */ "../views/Register"),
  },
  {
    path: "/tkey-input",
    name: "TKeyInput",
    component: () => import(/* webpackChunkName: "tkey-input" */ "../views/TKeyInput"),
  },
  {
    path: "/sdk-modal",
    name: "SdkModal",
    component: () => import(/* webpackChunkName: "sdk-modal" */ "../views/SdkModal"),
  },
  {
    path: "/popup",
    name: "Popup",
    component: () => import(/* webpackChunkName: "popup" */ "../views/Popup"),
  },
  {
    path: "/popup-window",
    name: "PopupWindow",
    component: () => import(/* webpackChunkName: "popup-window" */ "../views/PopupWindow"),
  },
  {
    path: "/wallet",
    component: () => import(/* webpackChunkName: "wallet-main" */ "../views/Wallet"),
    children: [
      {
        path: "/",
        name: "WalletHomeDefault",
        component: () => import(/* webpackChunkName: "wallet-base" */ "../views/Wallet/Home"),
      },
      {
        path: "home",
        name: "WalletHome",
        component: () => import(/* webpackChunkName: "wallet-home" */ "../views/Wallet/Home"),
      },
      {
        path: "account",
        name: "WalletAccount",
        component: () => import(/* webpackChunkName: "wallet-account" */ "../views/Wallet/Account"),
      },
      {
        path: "apps",
        name: "WalletApps",
        component: () => import(/* webpackChunkName: "wallet-apps" */ "../views/Wallet/Apps"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
