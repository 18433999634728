import Bowser from "bowser";
import log from "loglevel";

import getCurrentLogLevel from "./logutils";

const logLevel = getCurrentLogLevel();
log.setLevel(logLevel);
log.setDefaultLevel(logLevel);

const browserInfo = Bowser.parse(navigator.userAgent);
log.info(JSON.stringify(browserInfo), "current browser info");

export default browserInfo;
