import * as Sentry from "@sentry/vue";
import { TORUS_NETWORK } from "@toruslabs/constants";
import CustomAuth, { AggregateLoginParams, LOGIN_TYPE, SubVerifierDetails } from "@toruslabs/customauth";
import { getPublic, sign, verify } from "@toruslabs/eccrypto";
import { get, post } from "@toruslabs/http-helpers";
import { decryptData, encryptData, keccak256 } from "@toruslabs/metadata-helpers";
import {
  JRPCEngineEndCallback,
  JRPCEngineNextCallback,
  JRPCMiddleware,
  JRPCRequest,
  JRPCResponse,
  LoginConfig,
  OriginData,
  SerializableError,
  UserData,
} from "@toruslabs/openlogin-jrpc";
import { ExtraLoginOptions, jsonToBase64, keccak } from "@toruslabs/openlogin-utils";
import { ENGINE_MAP } from "bowser";
import log from "loglevel";

import browserInfo from "@/browserConfig";
import config from "@/config";
import { dappModulesStoreModule, deviceModule, loginPerfModule, pidModule, registerDappModule, tKeyModule, userModule } from "@/store";
import { generateCustomAuthParams, generateWebAuthnCustomAuthParams } from "@/utils/customauth";
import { LOCAL_STORAGE_KEY, OPENLOGIN_DAPP_MODULE_KEY, OPENLOGIN_METHOD, SESSION_STORAGE_KEY, WEBAUTHN_LOGIN_PROVIDER } from "@/utils/enums";
import { LoginConfigItem, MfaLevelType, TouchIDPreferences, WebAuthnFlow, WhiteLabelParams } from "@/utils/interfaces";
import { getBufferFromHexKey, getJoinedKey, isWebAuthnAvailable, redirectToDapp } from "@/utils/utils";

// #region types

type PIDSetParamRequest = Record<string, unknown> & { pid: string; data: Record<string, unknown> };

type ValidatedJRPCRequest<T> = {
  clientId: string;
  pid?: string;
  sessionId?: string;
  user?: string;
  origin?: string;
  redirect?: boolean;
  popupWindow?: boolean;
  loginConfig: LoginConfig;
  webauthnTransports?: AuthenticatorTransport[];
  _sessionNamespace?: string;
} & Required<JRPCRequest<T>>;

// #endregion types

// #region method middlewares

log.info("3pc", config.isStorageAvailable[LOCAL_STORAGE_KEY], config.isStorageAvailable[SESSION_STORAGE_KEY]);

export const openlogin_check_3PC_support = (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): void => {
  let thirdPartyCookieSupport = true;
  // brave
  if ((navigator as unknown as { brave: boolean })?.brave) {
    thirdPartyCookieSupport = false;
  }
  // All webkit & gecko engine instances use itp (intelligent tracking prevention -
  // https://webkit.org/tracking-prevention/#intelligent-tracking-prevention-itp)
  if (browserInfo.engine.name === ENGINE_MAP.WebKit || browserInfo.engine.name === ENGINE_MAP.Gecko) {
    thirdPartyCookieSupport = false;
  }
  if (!config.isStorageAvailable[LOCAL_STORAGE_KEY]) {
    thirdPartyCookieSupport = false;
  }
  res.result = { support3PC: thirdPartyCookieSupport };
  end();
};

export const openlogin_get_data = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  // validations
  try {
    if (config.torusNetwork === TORUS_NETWORK.TESTNET) {
      // using console log because it shouldn't be affected by loglevel config
      // eslint-disable-next-line no-console
      console.log("%c WARNING! You are on testnet. Please set network: 'mainnet' in production", "color: #FF0000");
    }
    const r = req as ValidatedJRPCRequest<Record<string, string>[]>;
    if (!r.origin) throw new Error(`invalid request origin for method ${req.method}`);
    userModule.setCurrentClientId(r.clientId);
    registerDappModule(r.clientId);
    const currentDappModule = dappModulesStoreModule.dappModules[r.clientId];
    const { sessionId, _sessionNamespace } = r;
    if (sessionId) {
      try {
        const publicKeyHex = getPublic(getBufferFromHexKey(sessionId)).toString("hex");
        const url = new URL(`${config.storageServerUrl}/store/get`);
        url.searchParams.append("key", publicKeyHex);
        if (_sessionNamespace) url.searchParams.append("namespace", _sessionNamespace);
        const encData: { message: string; success: boolean } = await get(url.href);
        if (encData.message) {
          const loginDetails = await decryptData<{ privKey: string; store: Record<string, string> }>(sessionId, encData.message);
          const { email, name, profileImage, aggregateVerifier, verifier, verifierId, typeOfLogin, idToken, accessToken } = loginDetails.store || {};
          res.result = {
            ...loginDetails,
            store: {
              ...loginDetails.store,
              email: email ?? "",
              name: name ?? "",
              profileImage: profileImage ?? "",
              aggregateVerifier: aggregateVerifier ?? "",
              verifier: verifier ?? "",
              verifierId: verifierId ?? "",
              typeOfLogin: typeOfLogin as LOGIN_TYPE,
              idToken: idToken ?? "",
              accessToken: accessToken ?? "",
            },
          };
          currentDappModule.updateState({ privateKey: loginDetails?.privKey });
          userModule.setUserInfo({
            email: email ?? "",
            name: name ?? "",
            profileImage: profileImage ?? "",
            aggregateVerifier: aggregateVerifier ?? "",
            verifier: verifier ?? "",
            verifierId: verifierId ?? "",
            typeOfLogin: typeOfLogin as LOGIN_TYPE,
            idToken: idToken ?? "",
            accessToken: accessToken ?? "",
          });
          end();
          return;
        }
      } catch (error: unknown) {
        log.warn(error, "Session likely expired");
        if ((error as Response).status === 404) {
          await currentDappModule.logout();
          res.result = {
            privKey: "",
            store: {},
          };
          end();
          return;
        }
      }
    }
    const { email, aggregateVerifier, name, profileImage, typeOfLogin, verifier, verifierId } = userModule.userInfo;
    res.result = {
      privKey: currentDappModule.privateKey,
      store: {
        touchIDPreference: currentDappModule.touchIDPreference,
        email,
        aggregateVerifier,
        name,
        profileImage,
        typeOfLogin,
        verifier,
        verifierId,
      },
    };

    end();
  } catch (error) {
    log.error(error);
    res.error = new SerializableError({ code: -32602, message: (error as Error).message });
    end();
  }
};

export const openlogin_set_pid_data = (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): void => {
  // validations
  const r = req as ValidatedJRPCRequest<PIDSetParamRequest[]>;
  const params = r.params[0];
  pidModule.setPID({ pid: params.pid, data: params.data });
  res.result = {};
  end();
};

export const openlogin_logout = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  // validations
  const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;
  const { redirectUrl } = r.params[0];
  if (!r.user) {
    log.error("user must be present to logout");
    res.result = {
      alreadyRedirecting: true,
    };
    if (r.redirect) {
      // reinit loginPerf before redirecting
      // for backward compat
      loginPerfModule._reinit();
      await redirectToDapp(
        {
          redirectUrl: redirectUrl as string,
          popupWindow: !!r.popupWindow,
          sessionTime: 1,
          sessionId: r.sessionId as string,
          _sessionNamespace: r._sessionNamespace as string,
        },
        {
          pid: r.pid || "",
          result: {},
        }
      );
    }
    end();
  }
  if (r.clientId) {
    registerDappModule(r.clientId);
    const currentDappModule = dappModulesStoreModule.dappModules[r.clientId];
    if (currentDappModule.privateKey) {
      try {
        if (r.user !== getPublic(getBufferFromHexKey(currentDappModule.privateKey)).toString("hex")) {
          throw new Error("authenticating user does not match dapp key");
        }
      } catch (error: unknown) {
        res.error = new SerializableError({ code: -32603, message: (error as Error).message });
        end();
        return;
      }
    }
    await currentDappModule.logout();

    if (r.sessionId) {
      const privKey = getBufferFromHexKey(r.sessionId);
      const publicKeyHex = getPublic(privKey).toString("hex");
      const encData = await encryptData(r.sessionId.padStart(64, "0"), {});
      const signature = (await sign(privKey, keccak256(encData))).toString("hex");
      await post(`${config.storageServerUrl}/store/set`, {
        key: publicKeyHex,
        data: encData,
        signature,
        timeout: 1,
        namespace: r._sessionNamespace || undefined,
      });
    }
  }
  res.result = {};
  end();
};

export const openlogin_prompt = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  // validation
  const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;

  const {
    loginProvider,
    redirectUrl,
    getWalletKey,
    appState,
    _whiteLabelData,
    mfaLevel,
    dappShare,
    sessionTime,
    skipTKey,
    mobileOrigin,
    _sessionNamespace,
    _webauthnTransports,
  } = r.params[0];

  const redirectOrigin = new URL(redirectUrl as string).origin;
  if (redirectOrigin !== r.origin && redirectOrigin !== window.location.origin) throw new Error("redirectUrl is not on origin");

  userModule.setCurrentClientId(r.clientId);
  registerDappModule(r.clientId);

  const currentDappModule = dappModulesStoreModule.dappModules[r.clientId];
  currentDappModule.setLoginParams({
    clientId: r.clientId,
    currentLoginProvider: loginProvider as string,
    redirectUrl: redirectUrl as string,
    mfaLevel: mfaLevel as MfaLevelType,
    getWalletKey: typeof getWalletKey === "string" ? getWalletKey === "true" : Boolean(getWalletKey),
    appState: appState as string,
    dappShare: dappShare as string,
    sessionTime: sessionTime as number,
    skipTKey: typeof skipTKey === "string" ? skipTKey === "true" : Boolean(skipTKey),
    mobileOrigin: mobileOrigin as string,
    _sessionNamespace: _sessionNamespace as string,
    webauthnTransports: _webauthnTransports as AuthenticatorTransport[],
  });
  if (_whiteLabelData) currentDappModule.setWhiteLabel(_whiteLabelData as WhiteLabelParams);
  if (r.loginConfig) {
    Object.keys(r.loginConfig).forEach((y) => {
      currentDappModule.modifyCustomLoginConfig({
        loginProvider: y,
        cfg: r.loginConfig[y] as LoginConfigItem,
      });
    });
  }

  const dappUrl = new URL(redirectUrl as string);
  currentDappModule.setSiteMetadata({ name: dappUrl.hostname, url: dappUrl.origin });
  res.result = {
    alreadyRedirecting: false,
    redirectParams: {
      name: "SdkModal",
      hash: `#_pid=${r.pid}&b64Params=${jsonToBase64(r.params[0])}`,
    },
  };
  end();
};

// openlogin_login never meaningfully returns values through the JRPCEngine since it redirects
export const openlogin_login = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  try {
    // validation
    const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;

    const {
      loginProvider,
      redirectUrl,
      mfaLevel,
      getWalletKey,
      appState,
      extraLoginOptions,
      _whiteLabelData,
      dappShare,
      sessionTime,
      skipTKey,
      curve,
      mobileOrigin,
      _webauthnTransports,
      _sessionNamespace,
    } = r.params[0];

    const redirectOrigin = new URL(redirectUrl as string).origin;
    if (redirectOrigin !== r.origin && redirectOrigin !== window.location.origin) throw new Error("redirectUrl is not on origin");

    userModule.setCurrentClientId(r.clientId);
    registerDappModule(r.clientId);

    const currentDappModule = dappModulesStoreModule.dappModules[r.clientId];
    currentDappModule.setLoginParams({
      clientId: r.clientId,
      currentLoginProvider: loginProvider as string,
      redirectUrl: redirectUrl as string,
      skipTKey: typeof skipTKey === "string" ? skipTKey === "true" : Boolean(skipTKey),
      mfaLevel: mfaLevel as MfaLevelType,
      getWalletKey: typeof getWalletKey === "string" ? getWalletKey === "true" : Boolean(getWalletKey),
      appState: appState as string,
      dappShare: dappShare as string,
      sessionTime: sessionTime as number,
      sessionId: r.sessionId as string,
      curve: curve as string,
      mobileOrigin: mobileOrigin as string,
      webauthnTransports: _webauthnTransports as AuthenticatorTransport[],
      _sessionNamespace: _sessionNamespace as string,
    });
    if (_whiteLabelData) currentDappModule.setWhiteLabel(_whiteLabelData as WhiteLabelParams);
    const customAuth = new CustomAuth({
      baseUrl: window.location.origin,
      redirectPathName: config.redirectPath,
      uxMode: "redirect",
      network: config.torusNetwork,
      enableLogging: config.logLevel !== "error",
      locationReplaceOnRedirect: true,

      // v2
      metadataUrl: config.metadataHost,
      enableOneKey: true,
      storageServerUrl: config.storageServerUrl,
      sentry: Sentry,
      web3AuthClientId: userModule.currentDappClientId || OPENLOGIN_DAPP_MODULE_KEY,
    });

    await customAuth.init({ skipSw: true, skipInit: true });

    if (r.loginConfig) {
      Object.keys(r.loginConfig).forEach((y) => {
        currentDappModule.modifyCustomLoginConfig({
          loginProvider: y,
          cfg: r.loginConfig[y] as LoginConfigItem,
        });
      });
    }

    const dappUrl = new URL(redirectUrl as string);
    currentDappModule.setSiteMetadata({ name: dappUrl.hostname, url: dappUrl.origin });

    if (loginProvider === "unselected") {
      res.result = {
        alreadyRedirecting: false,
        redirectParams: {
          name: "SdkModal",
          hash: `#redirectModal=true&_pid=${r.pid}&b64Params=${jsonToBase64(r.params[0])}`,
        },
      };
      end();
      return;
    }

    // fast login
    const currVerifier = currentDappModule.loginConfig[currentDappModule.currentLoginProvider]?.verifier;
    if (!currVerifier) {
      throw new Error("Invalid loginProvider or custom loginProvider data not available");
    }
    let lastVerifierId = deviceModule.verifierToLastLoggedInVerifierID[currVerifier];
    if ((extraLoginOptions as ExtraLoginOptions)?.login_hint) {
      lastVerifierId = (extraLoginOptions as ExtraLoginOptions)?.login_hint || lastVerifierId;
    }

    if (deviceModule.isTouchIDRegistered) {
      if (lastVerifierId) {
        const touchIDPref = deviceModule.touchIDVerifierIDMap[getJoinedKey(currVerifier, lastVerifierId)];
        if (touchIDPref === TouchIDPreferences.ENABLED) {
          if (currentDappModule.touchIDPreference === TouchIDPreferences.UNSET) {
            currentDappModule.setTouchIDPreference(TouchIDPreferences.ENABLED);
          }
          let credIdSuggestions: string[];
          let transports: AuthenticatorTransport[] = ["internal"];
          const scopedCredIdCache = deviceModule.credIdMapCache[getJoinedKey(currVerifier, lastVerifierId)];
          if (scopedCredIdCache && scopedCredIdCache.credIds.length > 0) {
            credIdSuggestions = scopedCredIdCache.credIds;
            if (scopedCredIdCache.transports) transports = scopedCredIdCache.transports;
          } else {
            credIdSuggestions = deviceModule.credIdCache;
          }
          const available = await isWebAuthnAvailable(transports);
          if (currentDappModule.touchIDPreference === TouchIDPreferences.ENABLED && available) {
            // this.doneLoading = true;

            const loginOptions = {
              ...generateWebAuthnCustomAuthParams(currentDappModule.loginConfig[WEBAUTHN_LOGIN_PROVIDER], {
                client: currentDappModule.clientId,
                currentLoginProvider: currentDappModule.currentLoginProvider,
                credIdCache: JSON.stringify(credIdSuggestions),
                credTransports: transports.join(","),
                webAuthnFlow: WebAuthnFlow.LOGIN,
                oAuthVerifierId: lastVerifierId,
                oAuthAggregateVerifier: currVerifier,
                popupWindow: (!!r.popupWindow).toString(),
                pid: r.pid || "",
                extraLoginOptions: JSON.stringify(extraLoginOptions || {}),
                whiteLabel: JSON.stringify(currentDappModule.whiteLabel),
                keyMode: tKeyModule.keyMode,
                webauthnTransports: currentDappModule.webauthnTransports.join(","),
              }),
              registerOnly: false,
            };

            type CustomExtraOptions = ExtraLoginOptions & { domain: string };

            loginOptions.jwtParams = { ...loginOptions.jwtParams, ...(extraLoginOptions as CustomExtraOptions) };
            customAuth.torus.enableOneKey = false;
            await customAuth.triggerLogin(loginOptions);

            res.result = {
              alreadyRedirecting: true,
            };
            end();
            return;
          }
        }
      }
    }

    // redirect to 3rd party for login
    const localConfig = currentDappModule.loginConfig[currentDappModule.currentLoginProvider];

    const customAuthArgs = generateCustomAuthParams(
      {
        ...localConfig,
        jwtParameters: {
          ...localConfig.jwtParameters,
          ...(extraLoginOptions as ExtraLoginOptions),
        },
      },
      {
        client: currentDappModule.clientId,
        currentLoginProvider: currentDappModule.currentLoginProvider,
        popupWindow: (!!r.popupWindow).toString(),
        pid: r.pid || "",
        whiteLabel: JSON.stringify(currentDappModule.whiteLabel),
        keyMode: tKeyModule.keyMode,
        isCustomVerifier: currentDappModule.isCustomVerifier.toString(),
      }
    );
    if (localConfig.verifierSubIdentifier) {
      await customAuth.triggerAggregateLogin(customAuthArgs as AggregateLoginParams);
    } else {
      await customAuth.triggerLogin(customAuthArgs as SubVerifierDetails);
    }
    // this.doneLoading = true;
    res.result = {
      alreadyRedirecting: true,
    };
    end();
    return;
  } catch (error: unknown) {
    log.error(error);
    res.error = new SerializableError({ code: -32602, message: (error as Error).message });
    end();
  }
};

export const scaffoldMiddlewares = {
  [OPENLOGIN_METHOD.CHECK_3PC_SUPPORT]: openlogin_check_3PC_support,
  [OPENLOGIN_METHOD.GET_DATA]: openlogin_get_data,
  [OPENLOGIN_METHOD.LOGIN]: openlogin_login,
  [OPENLOGIN_METHOD.LOGOUT]: openlogin_logout,
  [OPENLOGIN_METHOD.SET_PID_DATA]: openlogin_set_pid_data,
  [OPENLOGIN_METHOD.PROMPT]: openlogin_prompt,
};

// #endregion method middlewares

// #region session middleware

export async function validateUser(clientId: string, user: string, userSig: string, userData: UserData): Promise<void> {
  await verify(
    Buffer.from(user, "hex"),
    Buffer.from(keccak("keccak256").update(JSON.stringify(userData)).digest("hex"), "hex"),
    Buffer.from(userSig, "base64")
  );

  // more than 60s ago
  if (parseInt(userData.timestamp, 10) < Date.now() - 60 * 1000) {
    throw new Error("user data expired");
  }

  if (userData.clientId !== clientId) {
    throw new Error("user did not sign matching clientId");
  }
}

export async function validateRedirect(clientId: string, origin: string, originData: OriginData): Promise<void> {
  const originUrl = new URL(origin);
  if (config.alwaysAllowedHosts.includes(originUrl.hostname)) {
    return;
  }

  const b64OriginSig = originData[origin];
  if (b64OriginSig) {
    await verify(
      Buffer.from(clientId, "base64"),
      Buffer.from(keccak("keccak256").update(origin).digest("hex"), "hex"),
      Buffer.from(b64OriginSig, "base64")
    );
    return;
  }
  throw new Error(
    `could not validate redirect, please whitelist your domain: ${origin} for provided clientId ${clientId} at https://dashboard.web3auth.io.
    Also, this project is on ${config.torusNetwork} network. Please ensure the the used Client ID belongs to this network.`
  );
}

export const sessionMiddleware: JRPCMiddleware<unknown, unknown> = async function a(
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> {
  // validations
  const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;
  if (r.params && Object.keys(r.params).length > 0) {
    try {
      let param = r.params[0];
      const { _pid } = param;

      // merge params with presetParams based on pid
      if (_pid) {
        const pid = _pid as string;
        const presetParams = pidModule.pidStore[pid];
        param = { ...presetParams, ...param };
      }
      const { _user, _userSig, _userData, _origin, _originData, _loginConfig, _clientId, _redirect, _popupWindow, _sessionId, _sessionNamespace } =
        param;

      // defaults
      r.redirect = _redirect as boolean;
      r.clientId = _clientId as string;
      r.pid = _pid as string;
      r.user = "";
      r.origin = "";
      r.popupWindow = !!_popupWindow;
      r.loginConfig = _loginConfig as LoginConfig;
      r.sessionId = _sessionId as string;
      r._sessionNamespace = _sessionNamespace as string;
      if (_origin && _originData && r.clientId) {
        // TODO: fetch and merge originData from server based on clientId
        const origin = _origin as string;
        const originData = _originData as OriginData;
        await validateRedirect(r.clientId, origin, originData);
        r.origin = origin;
      }

      if (_user && _userSig && _userData) {
        const user = _user as string;
        const userSig = _userSig as string;
        const userData = _userData as UserData;
        await validateUser(r.clientId, user, userSig, userData);
        r.user = user;
      }
      next((done) => {
        res.result = { ...(res.result as Record<string, unknown>), _origin, _pid, _clientId, _popupWindow, _sessionId, _sessionNamespace };
        return done();
      });
    } catch (error) {
      log.error(error);
      res.error = new SerializableError({ code: -32602, message: (error as Error).message });
      return end();
    }
  }
  return next();
};

// #endregion session middleware
