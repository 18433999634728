import { AGGREGATE_VERIFIER, AggregateLoginParams, SubVerifierDetails, TorusGenericObject } from "@toruslabs/customauth";

import { LoginConfigItem } from "./interfaces";

export function generateCustomAuthParams(
  localConfig: LoginConfigItem,
  customState: TorusGenericObject = {}
): AggregateLoginParams | SubVerifierDetails {
  if (localConfig.verifierSubIdentifier) {
    return {
      aggregateVerifierType: AGGREGATE_VERIFIER.SINGLE_VERIFIER_ID,
      verifierIdentifier: localConfig.verifier,
      subVerifierDetailsArray: [
        {
          typeOfLogin: localConfig.typeOfLogin,
          verifier: localConfig.verifierSubIdentifier,
          clientId: localConfig.clientId,
          jwtParams: localConfig.jwtParameters,
          customState,
        },
      ],
    };
  }
  return {
    typeOfLogin: localConfig.typeOfLogin,
    verifier: localConfig.verifier,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState,
  };
}

export function generateWebAuthnCustomAuthParams(localConfig: LoginConfigItem, customState: TorusGenericObject = {}): SubVerifierDetails {
  return {
    typeOfLogin: localConfig.typeOfLogin,
    verifier: localConfig.verifier,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState,
  };
}
