import log from "loglevel";

import { client } from "@/apollo";
import config from "@/config";
import {
  Device,
  UpdateDevicePayload,
  UpdateLoginState,
  UpdateUserPayload,
  User,
  UserDapp,
  UserLoginPayload,
  VerifyAndRegisterPayload,
} from "@/utils/__generated__/graphql-types";
import { hashMessage, signMessage } from "@/utils/signMessage";
import { getUserLanguage } from "@/utils/utils";

import {
  GetAuthTokenMutation,
  GetAuthTokenMutationVariables,
  getServerTimeOffsetQuery,
  getServerTimeOffsetQueryVariables,
  MessageMutation,
  MessageMutationVariables,
  verifyMessageAndRegisterMutation,
  verifyMessageAndRegisterMutationVariables,
} from "../operations/__generated__/auth";
import {
  deleteDeviceMutationVariables,
  fetchDeviceQuery,
  fetchDeviceQueryVariables,
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables,
} from "../operations/__generated__/device";
import { UpdateLoginRecordMutationVariables, UserLoginMutation, UserLoginMutationVariables } from "../operations/__generated__/loginrecord";
import {
  fetchUserDappsQuery,
  fetchUserDappsQueryVariables,
  fetchUserInfoQuery,
  fetchUserInfoQueryVariables,
  fetchUserUniqueDappsQuery,
  fetchUserUniqueDappsQueryVariables,
  UpdateUserMutationVariables,
} from "../operations/__generated__/user";
import { GET_EXTERNAL_AUTH_TOKEN, GET_MESSAGE, GET_SERVER_TIME_OFFSET, VERIFY_AND_REGISTER } from "../operations/auth";
import { DELETE_DEVICE, FETCH_USER_DEVICES, UPDATE_DEVICE } from "../operations/device";
import { INITIATE_USER_LOGIN, UPDATE_LOGIN_RECORD } from "../operations/loginrecord";
import { UPDATE_USER_INFO, USER_DAPPS, USER_INFO, USER_UNIQUE_DAPPS } from "../operations/user";

const verifyMessageAndRegisterUser = async (
  params: Omit<VerifyAndRegisterPayload, "signed_message">,
  privateKey: string
): Promise<
  | {
      user: User;
      userDapp: UserDapp;
      device: Device;
      idToken: string;
      clientTimeOffset: number;
      messageForSign: string;
    }
  | null
  | undefined
> => {
  try {
    const {
      device_id,
      client_id,
      hostname,
      verifier,
      verifier_id,
      tkey_backup_emails,
      tkey_creation_factor,
      tkey_password,
      tkey_public_address,
      tkey_threshold,
      theme,
      locale,
      user_type,
      public_address,
      session_nonce,
      network,
    } = params;

    const { data: authData } = await client.mutate<MessageMutation, MessageMutationVariables>({
      mutation: GET_MESSAGE,
      variables: {
        public_address,
        client_time_offset: Math.floor(Date.now() / 1000),
        session_nonce,
      },
    });
    const messageForSign = authData?.res.message;
    if (messageForSign) {
      if (!messageForSign.startsWith("Openlogin Signin")) throw new Error("Cannot sign an invalid message");
      const hashedMessage = hashMessage(messageForSign);
      const signedMessage = signMessage(privateKey, hashedMessage.toString("hex"));

      const payload: VerifyAndRegisterPayload = {
        client_id,
        hostname,
        verifier,
        verifier_id,
        user_type,
        origin: config.torusNetwork,
        signed_message: signedMessage,
        public_address,
        network: network as string,
        session_nonce,
        locale: locale || getUserLanguage(),
      };
      if (tkey_backup_emails) payload.tkey_backup_emails = tkey_backup_emails;
      if (tkey_creation_factor) payload.tkey_creation_factor = tkey_creation_factor;
      if (tkey_password) payload.tkey_password = tkey_password;
      if (tkey_public_address) payload.tkey_public_address = tkey_public_address;
      if (tkey_threshold) payload.tkey_threshold = tkey_threshold;
      if (device_id) payload.device_id = device_id;
      if (theme) payload.theme = theme;

      const { data } = await client.mutate<verifyMessageAndRegisterMutation, verifyMessageAndRegisterMutationVariables>({
        mutation: VERIFY_AND_REGISTER,
        variables: { verify_register_payload: payload },
      });
      if (data?.res?.idToken) {
        const { idToken, user, userDapp, device } = data.res;
        // userModule.setAuthToken(authTokenData.token);
        return {
          idToken,
          clientTimeOffset: authData?.res.time_offset || 0,
          user: user as User,
          device: device as Device,
          userDapp: userDapp as UserDapp,
          messageForSign,
        };
      }
    }
    throw new Error("Failed to authenticate user");
  } catch (error) {
    log.error("Failed to store register user", error);
    // await _apiErrorHandler(error);
    return null;
  }
};

const getExternalAuthToken = async (params: GetAuthTokenMutationVariables): Promise<string | undefined> => {
  try {
    const {
      app_public_key,
      client_id,
      timeout,
      curve,
      verifier,
      verifier_id,
      aggregate_verifier,
      profile_image,
      name,
      email,
      type_of_login,
      session_nonce,
      app_signed_message,
      oauth_public_key,
    } = params;
    const { data } = await client.mutate<GetAuthTokenMutation, GetAuthTokenMutationVariables>({
      mutation: GET_EXTERNAL_AUTH_TOKEN,
      variables: {
        app_public_key,
        client_id,
        timeout,
        curve,
        verifier,
        verifier_id,
        aggregate_verifier,
        profile_image,
        name,
        email,
        type_of_login,
        session_nonce,
        app_signed_message,
        oauth_public_key,
      },
    });
    return data?.res.token;
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to fetch external token", error);
    // await _apiErrorHandler(error);
    return undefined;
  }
};

const addUserLoginDetails = async (
  params: UserLoginPayload
): Promise<{ deviceId?: string; loginRecordId?: string; dappId?: number; loginCount?: number }> => {
  try {
    const {
      error_stack,
      client_id,
      hostname,
      webauthn_available,
      webauthn_enabled,
      login_route,
      share_index,
      time_taken,
      is_fast_login,
      has_skipped_tkey,
      os,
      os_version,
      browser_version,
      browser,
      platform,
      wallet_public_address,
      login_type,
      dapp_public_key,
      device_id,
      fetch_login_count,
      mfa_level,
      factors_used,
      mobile_origin,
      session_pub_key,
    } = params;
    const payload: UserLoginPayload = {
      client_id,
      hostname,
      webauthn_available: !!webauthn_available,
      login_route,
      time_taken,
      os: os || "unidentified",
      os_version: os_version || "unidentified",
      browser_version: browser_version || "unidentified",
      browser: browser || "unidentified",
      platform: platform || "unidentified",
      login_type,
      fetch_login_count,
      origin: config.torusNetwork,
      mfa_level,
      factors_used,
      mobile_origin,
    };
    if (webauthn_enabled !== undefined) payload.webauthn_enabled = webauthn_enabled;
    if (error_stack) payload.error_stack = error_stack;
    if (share_index) payload.share_index = share_index;
    if (is_fast_login) payload.is_fast_login = is_fast_login;
    if (has_skipped_tkey) payload.has_skipped_tkey = has_skipped_tkey;
    if (wallet_public_address) payload.wallet_public_address = wallet_public_address;
    if (dapp_public_key) payload.dapp_public_key = dapp_public_key;
    if (device_id) payload.device_id = device_id;
    if (mobile_origin) payload.mobile_origin = mobile_origin;
    if (session_pub_key) payload.session_pub_key = session_pub_key;

    const { data } = await client.mutate<UserLoginMutation, UserLoginMutationVariables>({
      mutation: INITIATE_USER_LOGIN,
      variables: {
        login_payload: payload,
      },
    });

    return {
      deviceId: data?.res.device_id,
      loginRecordId: data?.res.login_record_id,
      dappId: data?.res.dapp_id,
      loginCount: data?.res.user_login_count || 0,
    };
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to store login record info", error);
    // await _apiErrorHandler(error);
    return {
      deviceId: "",
      loginRecordId: "",
      dappId: undefined,
      loginCount: 0,
    };
  }
};

const updateUserInfo = async (params: UpdateUserPayload): Promise<void> => {
  try {
    const {
      wallet_public_address,
      tkey_backup_emails,
      tkey_creation_factor,
      tkey_password,
      tkey_public_address,
      tkey_threshold,
      theme,
      locale,
      tkey_backup_downloaded,
      tkey_backup_copied,
      tkey_backup_verified,
      mfa_time,
      dapp_public_key,
      dapp_id,
      always_skip_tkey,
      v2_wallet_key_enabled,
      backup_phrase_setup_at,
      backup_phrase_setup_email,
      device_id,
    } = params;
    const payload: UpdateUserPayload = {};

    // value can be zero as well, so adding a check for undefined
    if (tkey_backup_emails !== undefined) payload.tkey_backup_emails = tkey_backup_emails;

    if (tkey_creation_factor) payload.tkey_creation_factor = tkey_creation_factor;

    if (tkey_password) payload.tkey_password = tkey_password;

    if (tkey_public_address) payload.tkey_public_address = tkey_public_address;

    if (tkey_threshold) payload.tkey_threshold = tkey_threshold;

    if (wallet_public_address) payload.wallet_public_address = wallet_public_address;

    if (theme) payload.theme = theme;

    if (tkey_backup_downloaded) payload.tkey_backup_downloaded = tkey_backup_downloaded;

    if (tkey_backup_copied) payload.tkey_backup_copied = tkey_backup_copied;

    if (tkey_backup_verified) payload.tkey_backup_verified = tkey_backup_verified;

    if (mfa_time) payload.mfa_time = mfa_time;

    if (dapp_public_key) payload.dapp_public_key = dapp_public_key;

    if (dapp_id) payload.dapp_id = dapp_id;

    if (always_skip_tkey !== undefined) payload.always_skip_tkey = always_skip_tkey;

    if (v2_wallet_key_enabled !== undefined) payload.v2_wallet_key_enabled = v2_wallet_key_enabled;

    if (backup_phrase_setup_at !== undefined) payload.backup_phrase_setup_at = backup_phrase_setup_at;

    if (device_id) payload.device_id = device_id;

    if (backup_phrase_setup_email !== undefined) payload.backup_phrase_setup_email = backup_phrase_setup_email;

    payload.locale = locale || getUserLanguage();

    await client.mutate<string, UpdateUserMutationVariables>({
      mutation: UPDATE_USER_INFO,
      variables: {
        update_user_payload: payload,
      },
    });
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to store user info", error);
    throw error;
  }
};

const updateDeviceInfo = async (params: UpdateDevicePayload): Promise<string | undefined> => {
  try {
    const { share_index, device_id, webauthn_available, webauthn_enabled } = params;
    const payload: UpdateDevicePayload = {
      device_id,
    };

    if (share_index) payload.share_index = share_index;

    if (webauthn_available !== undefined) payload.webauthn_available = webauthn_available;

    if (webauthn_enabled !== undefined) payload.webauthn_enabled = webauthn_enabled;

    const res = await client.mutate<UpdateDeviceMutation, UpdateDeviceMutationVariables>({
      mutation: UPDATE_DEVICE,
      variables: {
        update_device_payload: payload,
      },
    });
    return res.data?.deviceId;
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to store device info", error);
    // await _apiErrorHandler(error);
    return "";
  }
};

const fetchDeviceById = async (id: string): Promise<fetchDeviceQuery["devices"][0] | null> => {
  try {
    const searchParams: fetchDeviceQueryVariables = {
      id,
    };

    const res = await client.query<fetchDeviceQuery, fetchDeviceQueryVariables>({
      query: FETCH_USER_DEVICES,
      variables: searchParams,
    });
    return res.data.devices[0];
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to fetch device by id", error);
    // await _apiErrorHandler(error);
    return null;
  }
};

const deleteDevice = async (share_index: string, device_id: string): Promise<void> => {
  try {
    await client.mutate<string, deleteDeviceMutationVariables>({
      mutation: DELETE_DEVICE,
      variables: {
        share_index,
        device_id,
      },
    });
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to delete device", error);
    // await _apiErrorHandler(error);
  }
};

const updateUserLoginDetails = async (params: UpdateLoginState): Promise<void> => {
  try {
    const {
      error_stack,
      login_route,
      time_taken,
      has_skipped_tkey,
      login_record_id,
      wallet_public_address,
      has_skipped_mfa,
      login_type,
      mfa_level,
      factors_used,
      mobile_origin,
    } = params;
    const payload: UpdateLoginState = {
      login_route,
      time_taken,
      login_record_id,
      wallet_public_address,
    };
    if (error_stack) payload.error_stack = error_stack;
    if (has_skipped_tkey) payload.has_skipped_tkey = has_skipped_tkey;
    if (wallet_public_address) payload.wallet_public_address = wallet_public_address;
    if (has_skipped_mfa !== undefined) payload.has_skipped_mfa = has_skipped_mfa;
    if (login_type) payload.login_type = login_type;
    if (mfa_level) payload.mfa_level = mfa_level;
    if (factors_used) payload.factors_used = factors_used;
    if (mobile_origin) payload.mobile_origin = mobile_origin;

    await client.mutate<string, UpdateLoginRecordMutationVariables>({
      mutation: UPDATE_LOGIN_RECORD,
      variables: {
        update_record_login_payload: payload,
      },
    });
  } catch (error) {
    // not throwing away error, to allow login to proceed even if data is not saved.
    log.error("Failed to update login record info", error);
    // await _apiErrorHandler(error);
  }
};

const getServerTimeOffset = async (): Promise<number> => {
  try {
    const { data } = await client.mutate<getServerTimeOffsetQuery, getServerTimeOffsetQueryVariables>({
      mutation: GET_SERVER_TIME_OFFSET,
      variables: {
        clientTime: Math.floor(Date.now() / 1000),
      },
    });
    return data?.offset || 0;
  } catch (error) {
    log.error("error while fetching server time offset", error);
    return 0;
  }
};

const getUserInfo = async (): Promise<fetchUserInfoQuery["info"] | null> => {
  try {
    const res = await client.query<fetchUserInfoQuery, fetchUserInfoQueryVariables>({
      query: USER_INFO,
    });
    return res.data.info;
  } catch (error) {
    log.error("Failed to fetch user info", error);
    return null;
  }
};

const getUserUniqueDapps = async (): Promise<fetchUserUniqueDappsQuery["info"] | null> => {
  try {
    const res = await client.query<fetchUserUniqueDappsQuery, fetchUserUniqueDappsQueryVariables>({
      query: USER_UNIQUE_DAPPS,
      fetchPolicy: "network-only",
    });
    return res.data.info;
  } catch (error) {
    log.error("Failed to fetch user unique dapps", error);
    return null;
  }
};

const getUserDappWithDevices = async (dapp_id: number): Promise<fetchUserDappsQuery["info"] | null> => {
  try {
    const searchParams: fetchUserDappsQueryVariables = {
      dapp_id,
      is_active: true,
    };

    log.info("check: getUserDapps", searchParams);

    const res = await client.query<fetchUserDappsQuery, fetchUserDappsQueryVariables>({
      query: USER_DAPPS,
      variables: searchParams,
      fetchPolicy: "network-only",
    });

    return res.data.info;
  } catch (error) {
    log.error("Failed to fetch user dapps", error);
    return null;
  }
};

export {
  addUserLoginDetails,
  deleteDevice,
  fetchDeviceById,
  getExternalAuthToken,
  getServerTimeOffset,
  getUserDappWithDevices,
  getUserInfo,
  getUserUniqueDapps,
  updateDeviceInfo,
  updateUserInfo,
  updateUserLoginDetails,
  verifyMessageAndRegisterUser,
};
