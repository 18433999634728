import ThresholdKey from "@tkey/core";
import ShareTransferModule from "@tkey/share-transfer";
import BN from "bn.js";
import bowser from "bowser";
import log from "loglevel";

import { AllDeviceShares, ModuleShareDescription, PendingShareTransferRequest } from "@/utils/interfaces";

import { CHROME_EXTENSION_STORAGE_MODULE_KEY, SHARE_TRANSFER_MODULE_KEY } from "../../utils/enums";
import { capitalizeFirstLetter, formatDate, getBrowserIcon } from "../../utils/utils";

export function parseShares(parsedShareDescriptions: ModuleShareDescription[]): AllDeviceShares {
  return parsedShareDescriptions.reduce((acc: AllDeviceShares, x: unknown) => {
    const y = x as ModuleShareDescription & {
      userAgent: string;
      dateAdded: string;
      date: string;
      customDeviceInfo?: string;
    };
    if (y.userAgent && y.shareIndex !== "1") {
      const browserInfo = bowser.parse(y.userAgent);
      const browserName = y.module === CHROME_EXTENSION_STORAGE_MODULE_KEY ? "Chrome Extension" : `${browserInfo.browser.name}`;
      let title = `${browserName}${y.module === CHROME_EXTENSION_STORAGE_MODULE_KEY ? "" : ` ${`V${browserInfo.browser.version}`}`}`;
      const customDeviceInfo = y.customDeviceInfo ? JSON.parse(y.customDeviceInfo) : null;
      let icon = getBrowserIcon(browserInfo.browser.name || "");
      if (customDeviceInfo && customDeviceInfo.browser) {
        title = customDeviceInfo.browser;
        icon = getBrowserIcon(customDeviceInfo.browser);
      }
      y.dateFormatted = formatDate(y.dateAdded || y.date);
      acc.push({
        customDeviceInfo,
        rawDateAdded: parseInt(y.dateAdded, 10),
        osDetails: `${browserInfo.os.name || ""}, ${capitalizeFirstLetter(browserInfo.platform.type || "")}`.trim(),
        icon,
        title,
        dateAdded: y.dateFormatted,
        module: y.module,
        index: y.shareIndex,
        userAgent: y.userAgent,
        indexShort: y.shareIndex.slice(0, 4),
      });
    }
    return acc;
  }, [] as AllDeviceShares);
}

export async function getPendingShareTransferRequests(tKey: ThresholdKey): Promise<PendingShareTransferRequest[]> {
  const shareTransferModule = tKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule;
  if (!shareTransferModule) return [];
  const latestShareTransferStore = await shareTransferModule.getShareTransferStore();
  const pendingRequests = Object.keys(latestShareTransferStore).reduce((acc: PendingShareTransferRequest[], x) => {
    const browserDetail = bowser.parse(latestShareTransferStore[x].userAgent);
    const { userIp } = latestShareTransferStore[x];
    if (!latestShareTransferStore[x].encShareInTransit)
      acc.push({ ...latestShareTransferStore[x], browserDetail, userIp, encPubKeyX: x } as PendingShareTransferRequest);
    return acc;
  }, []);
  return pendingRequests;
}

export async function getAllPrivateKeys(
  newTKey: ThresholdKey,
  privKey: BN
): Promise<
  {
    privKey: string;
  }[]
> {
  const hexKeys = [];
  if (privKey) {
    hexKeys.push({ privKey: privKey.toString("hex", 64) });
    log.info("privKeys are available");
  }
  return hexKeys;
}
