import { Component, Vue } from "vue-property-decorator";

import i18n from "./plugins/i18n-setup";

function parseMarkdown(markdownText: string): string {
  const htmlText = markdownText
    .replace(/\*\*(.*)\*\*/gim, "<b>$1</b>")
    .replace(/\*(.*)\*/gim, "<i>$1</i>")
    .replace(/\n$/gim, "<br />");

  return htmlText.trim();
}

@Component
export default class TranslationMixin extends Vue {
  t(data: string, replaceList?: Record<string, string>): string {
    if (data === "") return data;
    const translated = i18n.t(`${data}`, replaceList);
    return parseMarkdown(translated as string);
  }
}
