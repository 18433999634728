import { privateToAddress } from "@ethereumjs/util";
import type { Transaction } from "@sentry/types";
import * as Sentry from "@sentry/vue";
import { KeyDetails, Point, ShareStore, StringifiedType } from "@tkey/common-types";
import ThresholdKey, { CoreError } from "@tkey/core";
import SecurityQuestionsModule from "@tkey/security-questions";
import ShareSerializationModule from "@tkey/share-serialization";
import ShareTransferModule from "@tkey/share-transfer";
import TorusStorageLayer from "@tkey/storage-layer-torus";
import WebStorageModule, { storeShareOnLocalStorage } from "@tkey/web-storage";
import CustomAuth, { TorusKey } from "@toruslabs/customauth";
import { post } from "@toruslabs/http-helpers";
import BN from "bn.js";
import { isMatch, pickBy } from "lodash-es";
import log from "loglevel";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import config from "@/config";
import { deleteDevice, updateDeviceInfo } from "@/graphql/mutations";
import createTKeyInstance from "@/services/TKey/TkeyFactory";
import { getAllPrivateKeys, getPendingShareTransferRequests, parseShares } from "@/services/TKey/TkeyUtils";
import { UpdateUserPayload } from "@/utils/__generated__/graphql-types";
import {
  CHECK_IF_TKEY_EXIST,
  CHROME_EXTENSION_STORAGE_MODULE_KEY,
  DEVICE_MODULE_KEY,
  OPENLOGIN_DAPP_MODULE_KEY,
  PASSWORD_QUESTION,
  SECURITY_QUESTIONS_MODULE_KEY,
  SESSION_STORAGE_KEY,
  SHARE_SERIALIZATION_MODULE_KEY,
  SHARE_TRANSFER_MODULE_KEY,
  TKEY_MODULE_KEY,
  TKEY_REQUIRE_MORE_INPUT,
  TKEY_SHARE_TRANSFER_INTERVAL,
  USER_MODULE_KEY,
  WEB_STORAGE_MODULE_KEY,
  WEBAUTHN_DEVICE_MODULE_KEY,
} from "@/utils/enums";
import {
  CalculateSettingsPageParams,
  CreateNewTKeyParams,
  KeyMode,
  ModuleShareDescription,
  OnDeviceShare,
  PendingShareTransferRequest,
  SetDeviceWebAuthnRegisteredParams,
  SettingsPageData,
  ShareRequestInfo,
  ShareSerializationEmailShares,
  TKeyAccount,
  TkeyInputParams,
  TorusUserInfo,
  TouchIDPreferences,
  WebAuthnDeviceShares,
} from "@/utils/interfaces";
import { canPreserveState, capitalizeFirstLetter, formatDate, getBufferFromHexKey, getCustomDeviceInfo, getJoinedKey } from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";
import loginPerfModule from "./loginPerf";
import userModule from "./user";

// function beforeUnloadHandler(e: BeforeUnloadEvent) {
//   // Cancel the event
//   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
//   // Chrome requires returnValue to be set
//   e.returnValue = "You have unfinished changes";
// }

interface ITkeyModuleState {
  tKey: ThresholdKey;
  postboxKey: string;
  settingsPageData: SettingsPageData;
  parsedShareDescriptions: unknown[];
  keyDetails: KeyDetails;

  requestStatusCheckId?: number;
  error?: string;
  shareTransferRequests?: PendingShareTransferRequest[];
  success?: string;
  currentEncPubKeyX?: string;
  initialized: boolean;
  tKeyPrivKey: string;
  keyMode: KeyMode;
}

interface IAdditionalSecurity {
  recoveryEmail: string;
}

@Module({
  namespaced: true,
  name: TKEY_MODULE_KEY,
  store,
  dynamic: true,
  preserveState: canPreserveState(TKEY_MODULE_KEY, SESSION_STORAGE_KEY),
})
class TKeyModule extends VuexModule {
  tKey = new ThresholdKey({ manualSync: true });

  postboxKey = "";

  settingsPageData: SettingsPageData = {
    deviceShare: { available: false },
    allDeviceShares: [],
    exportedEmailShares: {},
    otherShares: [],
    passwordShare: { available: false },
    webauthnDeviceShares: {},
    threshold: "",
  };

  parsedShareDescriptions: unknown[] = [];

  keyDetails: KeyDetails = {
    pubKey: new Point("0", "0"),
    requiredShares: 0,
    threshold: 0,
    totalShares: 0,
    shareDescriptions: {},
  };

  requestStatusCheckId = 0;

  additionalSecurity: IAdditionalSecurity | null = null;

  error = "";

  shareTransferRequests: PendingShareTransferRequest[] = [];

  success = "";

  currentEncPubKeyX = "";

  initialized = false;

  tKeyPrivKey = "";

  creationFactor = "";

  keyMode = "v1";

  get shareRequestDetails(): ShareRequestInfo | undefined {
    const shortIndex = this.settingsPageData.deviceShare.share?.share.shareIndex.toString("hex").slice(0, 4) || "";
    if (this.shareTransferRequests?.length > 0) {
      const currentShareRequest = this.shareTransferRequests[0];

      return {
        shortIndex,
        timestamp: currentShareRequest ? new Date(currentShareRequest.timestamp).toLocaleString() : "",
        browserName: `${currentShareRequest.browserDetail.browser.name} V${currentShareRequest.browserDetail.browser.version}`,
        platformType: currentShareRequest.browserDetail.platform.type || "desktop",
        encPubKeyX: currentShareRequest.encPubKeyX,
        userIp: currentShareRequest.userIp || "",
      };
    }
    return undefined;
  }

  @Mutation
  updateState(state: Partial<ITkeyModuleState>) {
    const {
      tKey,
      settingsPageData,
      parsedShareDescriptions,
      keyDetails,
      requestStatusCheckId,
      error,
      shareTransferRequests,
      success,
      postboxKey,
      currentEncPubKeyX,
      initialized,
      tKeyPrivKey,
      keyMode,
    } = state;
    if (postboxKey) this.postboxKey = postboxKey;
    if (tKey) this.tKey = tKey;
    if (settingsPageData) this.settingsPageData = { ...settingsPageData };
    if (parsedShareDescriptions) this.parsedShareDescriptions = [...parsedShareDescriptions];
    if (keyDetails) this.keyDetails = { ...keyDetails };
    if (requestStatusCheckId) this.requestStatusCheckId = requestStatusCheckId;
    if (error) this.error = error;
    if (shareTransferRequests) this.shareTransferRequests = [...shareTransferRequests];
    if (success) this.success = success;
    if (currentEncPubKeyX) this.currentEncPubKeyX = currentEncPubKeyX;
    if (initialized !== undefined) this.initialized = initialized;
    if (tKeyPrivKey) this.tKeyPrivKey = tKeyPrivKey;
    if (keyMode) this.keyMode = keyMode;
  }

  @Mutation
  setAdditionalSecurity(value: IAdditionalSecurity | null) {
    this.additionalSecurity = value;
  }

  @Action
  async getTkeyInstance(): Promise<ThresholdKey> {
    if (this.initialized) return this.tKey;
    return new Promise((resolve) => {
      const unwatch = store.watch(
        (state: unknown) => {
          return ((state as Record<string, unknown>)[TKEY_MODULE_KEY] as ITkeyModuleState).initialized;
        },
        (newValue, oldValue) => {
          if (newValue !== oldValue && newValue === true) {
            resolve(this.tKey);
            log.info("resolved tKey");
            unwatch();
          }
        },
        {
          immediate: true,
        }
      );
    });
  }

  @Action
  async checkIfTKeyExists(postboxKey: BN): Promise<{ success: boolean; shareStore?: ShareStore }> {
    try {
      if (!postboxKey) return { success: false };
      const { clientTimeOffset = 0 } = this.context.rootState[USER_MODULE_KEY] || {};
      const storageLayer = new TorusStorageLayer({
        hostUrl: config.metadataHost,
        serverTimeOffset: clientTimeOffset,
      });
      const operationName = CHECK_IF_TKEY_EXIST;
      // start measuring perf
      window.performance.mark(`${operationName}_start`);
      const metadata = (await storageLayer.getMetadata({ privKey: postboxKey })) as ShareStore;
      loginPerfModule.markRouteAndTime({
        route: "auth",
        operation: operationName,
      });
      return { success: Object.keys(metadata).length > 0, shareStore: metadata };
    } catch (error) {
      log.error("unable to check for tkey", error);
      return { success: false };
    }
  }

  @Action
  async _init({ postboxKey, importKey, tKeyJson, shareStores, dappShare }: TkeyInputParams): Promise<void> {
    const { clientTimeOffset = 0 } = this.context.rootState[USER_MODULE_KEY] || {};
    const tKey = await createTKeyInstance({ postboxKey, importKey, tKeyJson, shareStores, dappShare, serverTimeOffset: clientTimeOffset });
    this.updateState({ postboxKey, tKey, initialized: true });
    await this.calculateSettingsPageData({ forceCheckOnDeviceShare: !!tKeyJson });
  }

  @Action
  async calculateSettingsPageData(params: CalculateSettingsPageParams = {}) {
    const localTKey = await this.getTkeyInstance();
    if (!localTKey.metadata) return;
    const { forceCheckOnDeviceShare = false } = params;
    const onDeviceShare = { available: false } as OnDeviceShare;
    const passwordShare = { available: false };
    const keyDetails = localTKey.getKeyDetails();
    const { shareDescriptions = {}, totalShares, threshold: thresholdShares } = keyDetails;

    const pubPoly = localTKey.metadata.getLatestPublicPolynomial();
    const pubPolyID = pubPoly.getPolynomialID();
    const currentPolynomialShares = Object.keys(localTKey.shares[pubPolyID]);
    const allShareIndexes = localTKey.metadata.getShareIndexesForPolynomial(pubPolyID);

    const availableShareDescriptionIndexes = Object.keys(shareDescriptions);

    const parsedShareDescriptions = availableShareDescriptionIndexes.reduce((acc: ModuleShareDescription[], x: string) => {
      const internalDescriptions = shareDescriptions[x].map((y) => ({ ...JSON.parse(y), shareIndex: x }));
      acc.push(...internalDescriptions);
      return acc;
    }, []);

    parsedShareDescriptions.forEach((x: { available: boolean; shareIndex: string }) => {
      x.available = currentPolynomialShares.includes(x.shareIndex);
    });

    // Exported email share
    const exportedEmailShares = parsedShareDescriptions.filter((x: { module: string }) => x.module === SHARE_SERIALIZATION_MODULE_KEY);
    const emailShares = exportedEmailShares.reduce((acc: ShareSerializationEmailShares, x) => {
      acc[x.shareIndex] = {
        index: x.shareIndex,
        indexShort: x.shareIndex.slice(0, 4),
        email: x.data as string,
        dateAdded: formatDate(x.date as string),
      };
      return acc;
    }, {});

    // log.info("localTKey.shares", localTKey.shares[pubPolyID]);
    // log.info("parsedShareDescriptions", parsedShareDescriptions);

    // Total device shares
    const allDeviceShares = parseShares(
      parsedShareDescriptions.filter((x) => x.module === CHROME_EXTENSION_STORAGE_MODULE_KEY || x.module === WEB_STORAGE_MODULE_KEY)
    );

    // WebAuthn device shares
    const webauthnDeviceShares = parsedShareDescriptions.reduce((acc: WebAuthnDeviceShares, x: ModuleShareDescription) => {
      if (x.module === WEBAUTHN_DEVICE_MODULE_KEY) {
        const val = {
          device: x.device as string,
          dateAdded: formatDate(x.dateAdded as string),
          index: x.shareIndex,
          indexShort: x.shareIndex.slice(0, 4),
          transports: (x.transports as AuthenticatorTransport[]) ?? ["internal"],
        };
        if (acc[x.shareIndex]) {
          acc[x.shareIndex].push(val);
        } else {
          acc[x.shareIndex] = [val];
        }
      }
      return acc;
    }, {});
    // For ondevice share
    if (!this.settingsPageData?.deviceShare.available || forceCheckOnDeviceShare) {
      try {
        const onDeviceLocalShare = await (localTKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).getDeviceShare();
        if (onDeviceLocalShare) {
          onDeviceShare.available = true;
          onDeviceShare.share = onDeviceLocalShare;
        }
      } catch {
        onDeviceShare.available = false;
      }
    }

    // password share
    const passwordModules = parsedShareDescriptions.filter((x) => x.module === SECURITY_QUESTIONS_MODULE_KEY);
    passwordShare.available = passwordModules.length > 0;

    // Current threshold
    const threshold = `${thresholdShares}/${totalShares}`;
    this.updateState({
      settingsPageData: {
        deviceShare: onDeviceShare,
        exportedEmailShares: emailShares,
        allDeviceShares,
        webauthnDeviceShares,
        passwordShare,
        threshold,
        otherShares: allShareIndexes.filter((value) => !availableShareDescriptionIndexes.includes(value) && value !== "1"),
      },
      parsedShareDescriptions,
      keyDetails,
      tKey: localTKey,
    });
  }

  @Mutation
  async setCreationFactor(factor: string) {
    this.creationFactor = factor;
  }

  @Action
  async appendTkeyCreationFactor({ factor, sync }: { factor: string; sync?: boolean }) {
    if (this.creationFactor && !this.creationFactor.includes(factor)) {
      const creationFactor = factor === "webauthn" ? `${factor}|${this.creationFactor}` : `${this.creationFactor}|${factor}`;
      this.setCreationFactor(creationFactor);
    } else {
      this.setCreationFactor(factor);
    }
    if (sync) {
      await userModule.updateUserPersistedInfo({ payload: { tkey_creation_factor: this.creationFactor } });
    }
  }

  @Action
  async ensureTKeyCreated({ keyInfo, userInfo }: { keyInfo: TorusKey; userInfo: TorusUserInfo }) {
    if (this.keyMode === "v1" || this.keyMode === "2/n") return;
    // Create a torus-direct instance to use its utility functions only
    const customAuth = new CustomAuth({
      baseUrl: window.location.origin,
      metadataUrl: config.metadataHost,
      network: config.torusNetwork,
      enableOneKey: true,
      storageServerUrl: config.storageServerUrl,
      sentry: Sentry,
      web3AuthClientId: userModule.currentDappClientId || OPENLOGIN_DAPP_MODULE_KEY,
    });
    await this.createNewTKey({
      postboxKey: customAuth.getPostboxKeyFrom1OutOf1(keyInfo.privateKey, keyInfo.metadataNonce),
      importKey: keyInfo.privateKey,
      loginProvider: capitalizeFirstLetter(userInfo.typeOfLogin),
      verifierId: userInfo.email || userInfo.verifierId,
      customDeviceInfo: getCustomDeviceInfo(),
      syncLocalTransitions: true,
    });
    this.updateState({ keyMode: "2/n" });
  }

  @Action
  async createNewTKey({
    postboxKey,
    importKey,
    password,
    backup,
    recoveryEmail,
    loginProvider,
    verifierId,
    syncLocalTransitions,
    customDeviceInfo,
  }: CreateNewTKeyParams): Promise<TKeyAccount[]> {
    await this._init({ postboxKey, importKey, shareStores: [] });
    const { tKey, settingsPageData } = this;
    this.appendTkeyCreationFactor({ factor: "device" });
    if (password) {
      await (tKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).generateNewShareWithSecurityQuestions(
        password,
        PASSWORD_QUESTION
      );

      this.appendTkeyCreationFactor({ factor: "password" });
    }

    // can't do some operations without key reconstruction
    const { privKey } = await tKey.reconstructKey(false);
    if (backup) {
      try {
        const { deviceShare } = settingsPageData;
        if (deviceShare.share) {
          await (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).storeDeviceShareOnFileStorage(deviceShare.share.share.shareIndex);
        }
      } catch (error) {
        log.error(error);
      }
    }

    if (recoveryEmail) {
      this.updateState({ tKey }); // update state again
      this.appendTkeyCreationFactor({ factor: "email" });
      await this.addRecoveryShare({
        recoveryEmail,
        reCalculate: false,
        loginProvider,
        verifierId,
        syncMetadata: !!syncLocalTransitions,
        updateBackend: false,
      });
    }
    // if (useSeedPhrase) {
    //   await this.addSeedPhrase(seedPhrase, false);
    // }

    const localTKey = await this.getTkeyInstance();

    await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
    if (customDeviceInfo) {
      await this.updateDeviceShareDescription({ sync: false, reCalculate: true, customDeviceInfo });
    }
    if (syncLocalTransitions) await localTKey.syncLocalMetadataTransitions();
    // this.startShareTransferRequestListener();
    const localTKeyPostGeneration = await this.getTkeyInstance();
    const hexKeys = await getAllPrivateKeys(localTKeyPostGeneration, privKey);
    if (hexKeys.length > 0) this.updateState({ tKeyPrivKey: hexKeys[0].privKey, tKey: localTKeyPostGeneration });
    const { walletKeyInfo } = this.context.rootState[USER_MODULE_KEY];

    if (syncLocalTransitions) {
      const userTkeyInfo: UpdateUserPayload = {
        tkey_public_address: privateToAddress(Buffer.from(this.tKeyPrivKey.padStart(64, "0"), "hex")).toString("hex"),
        tkey_backup_emails: Object.values(this.settingsPageData.exportedEmailShares).length,
        tkey_threshold: this.keyDetails.totalShares,
        tkey_creation_factor: this.creationFactor,
        tkey_password: !!password || null,
        wallet_public_address: walletKeyInfo.publicAddress,
      };
      await userModule.updateUserPersistedInfo({ payload: userTkeyInfo });
    }

    return hexKeys;
  }

  @Action
  async updateDeviceShareDescription(params: { sync: boolean; reCalculate: boolean; customDeviceInfo: Record<string, string> }): Promise<void> {
    try {
      const { sync, reCalculate, customDeviceInfo } = params;
      const localTKey = await this.getTkeyInstance();
      const deviceShareIndex = this.settingsPageData.deviceShare.share?.share.shareIndex.toString("hex");
      if (!deviceShareIndex) return;
      const deviceShare = this.settingsPageData.allDeviceShares.find((x) => x.index === deviceShareIndex);
      if (!deviceShare) return;
      const oldShareDescription: { module: string; userAgent: string; dateAdded: number; customDeviceInfo?: string } = {
        module: deviceShare.module,
        userAgent: deviceShare.userAgent,
        dateAdded: deviceShare.rawDateAdded as number,
      };
      if (deviceShare.customDeviceInfo) {
        oldShareDescription.customDeviceInfo = JSON.stringify(deviceShare.customDeviceInfo);
      }
      const stringifiedInfo = JSON.stringify(customDeviceInfo);
      if (stringifiedInfo !== oldShareDescription.customDeviceInfo) {
        const newShareDescription = {
          ...oldShareDescription,
          customDeviceInfo: stringifiedInfo,
        };
        await localTKey.updateShareDescription(deviceShareIndex, JSON.stringify(oldShareDescription), JSON.stringify(newShareDescription), true);
        if (sync) await localTKey.syncLocalMetadataTransitions();
        if (reCalculate) await this.calculateSettingsPageData();
      }
    } catch (error) {
      // Not important task. can swallow error
      log.error(error);
    }
  }

  @Action
  async syncMetadataTransitions() {
    try {
      const localTKey = await this.getTkeyInstance();
      await localTKey.syncLocalMetadataTransitions();
      this.updateState({ tKey: localTKey });
    } catch (err) {
      log.error("error while syncing metadata transitions", err);
      throw err;
    }
  }

  @Action
  async addRecoveryShare(params: {
    recoveryEmail: string;
    reCalculate: boolean;
    loginProvider: string;
    verifierId: string;
    syncMetadata: boolean;
    updateBackend?: boolean; // default is true
  }): Promise<void> {
    try {
      const { recoveryEmail, reCalculate, loginProvider, verifierId, syncMetadata, updateBackend = true } = params;
      const localTKey = await this.getTkeyInstance();
      const { newShareIndex } = await localTKey.generateNewShare();
      const emailShareDescription = {
        data: recoveryEmail,
        date: new Date(),
        module: "shareSerialization",
      };
      await localTKey.addShareDescription(newShareIndex.toString("hex"), JSON.stringify(emailShareDescription), true);
      if (syncMetadata) await localTKey.syncLocalMetadataTransitions();
      await this.sendRecoveryShareEmail({ recoveryEmail, shareIndex: newShareIndex.toString("hex"), loginProvider, verifierId });
      if (reCalculate) await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
      if (updateBackend) {
        const userTkeyInfo: UpdateUserPayload = {
          tkey_backup_emails: Object.values(this.settingsPageData.exportedEmailShares).length,
          tkey_threshold: this.keyDetails.totalShares,
          backup_phrase_setup_at: new Date().toISOString(),
          backup_phrase_setup_email: recoveryEmail,
        };
        await userModule.updateUserPersistedInfo({ payload: userTkeyInfo });
      }
    } catch (error) {
      log.error(error);
    }
  }

  @Action
  async sendRecoveryShareEmail(params: { recoveryEmail: string; shareIndex: string; loginProvider: string; verifierId: string }): Promise<void> {
    try {
      const { recoveryEmail, shareIndex, loginProvider, verifierId } = params;
      const serializedShare = await this.exportShare(shareIndex);
      log.info("sending serialized share");
      await post(config.tkeyEmail.host, {
        data: serializedShare,
        logo: config.tkeyEmail.logo,
        name: config.tkeyEmail.name,
        email: recoveryEmail,
        baseUrl: window.location.origin,
        typeOfLogin: loginProvider,
        network: config.torusNetwork,
        verifierId,
      });
    } catch (error) {
      log.error(error);
    }
  }

  @Action
  async setDeviceWebAuthnRegistered({ shareIndex, transports }: SetDeviceWebAuthnRegisteredParams): Promise<void> {
    const deviceShare = this.settingsPageData.allDeviceShares.find((x) => x.index === shareIndex);
    const webauthnDeviceShares = this.settingsPageData.webauthnDeviceShares[shareIndex] || [];
    log.info("finding device share for webauthn in all device shares", !deviceShare);
    if (!deviceShare) return;
    const webauthnShareAlreadyExists = webauthnDeviceShares.some((x) => x.device === deviceShare.osDetails);
    log.info("webauthn share already exists", webauthnShareAlreadyExists);
    if (webauthnShareAlreadyExists) return;
    log.info("adding webauthn share description");
    const localTKey = await this.getTkeyInstance();
    await localTKey.addShareDescription(
      shareIndex,
      JSON.stringify({
        module: WEBAUTHN_DEVICE_MODULE_KEY,
        device: deviceShare.osDetails,
        dateAdded: Date.now(),
        userAgent: deviceShare.userAgent,
        transports: transports ?? ["internal"],
      }),
      true
    );
    await localTKey.syncLocalMetadataTransitions();
    await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
  }

  @Action
  async startShareTransferRequestListener(): Promise<void> {
    if (this.requestStatusCheckId) clearInterval(this.requestStatusCheckId);
    if (this.keyMode === "v1" || this.keyMode === "2/n") {
      if (this.tKeyPrivKey) {
        const checkFn = async () => {
          try {
            const internalTKeyInstance = await this.getTkeyInstance();
            const pendingRequests = await getPendingShareTransferRequests(internalTKeyInstance);
            log.info(pendingRequests, "pending requests");
            this.updateState({ shareTransferRequests: pendingRequests });
            if (Object.keys(pendingRequests).length > 0) {
              clearInterval(this.requestStatusCheckId);
            }
          } catch (error) {
            clearInterval(this.requestStatusCheckId);
            log.error(error);
          }
        };
        checkFn();
        this.updateState({ requestStatusCheckId: Number(setInterval(checkFn, TKEY_SHARE_TRANSFER_INTERVAL)) });
      } else {
        throw CoreError.privateKeyUnavailable();
      }
    }
  }

  @Action
  stopShareTransferRequestListener(): void {
    if (this.requestStatusCheckId) clearInterval(this.requestStatusCheckId);
    this.updateState({ requestStatusCheckId: 0 });
  }

  @Mutation
  logout(): void {
    if (this.requestStatusCheckId) clearInterval(this.requestStatusCheckId);
    this.requestStatusCheckId = 0;
    this.tKey = new ThresholdKey({ manualSync: true });
    this.postboxKey = "";
    this.settingsPageData = {
      deviceShare: { available: false },
      allDeviceShares: [],
      webauthnDeviceShares: {},
      exportedEmailShares: {},
      otherShares: [],
      passwordShare: { available: false },
      threshold: "",
    };
    this.initialized = false;
    this.tKeyPrivKey = "";
    this.parsedShareDescriptions = [];
    this.creationFactor = "";
    this.keyDetails = {
      pubKey: new Point("0", "0"),
      requiredShares: 0,
      threshold: 0,
      totalShares: 0,
      shareDescriptions: {},
    };
  }

  @Action
  async makeShareTransferRequest(): Promise<TKeyAccount[]> {
    const localTKey = await this.getTkeyInstance();
    const shareTransferModule = localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule;
    await shareTransferModule.setShareTransferStore({});
    const currentEncPubKeyX = await shareTransferModule.requestNewShare(window.navigator.userAgent, localTKey.getCurrentShareIndexes());
    this.updateState({ currentEncPubKeyX });
    await shareTransferModule.startRequestStatusCheck(currentEncPubKeyX, true);

    let sentryTransaction: Transaction | undefined;
    try {
      sentryTransaction = Sentry.startTransaction({ name: "tkey/share-transfer" });
    } catch (err) {
      log.error("Sentry error", err);
    }

    try {
      const updatedTkey = await localTKey.updateSDK(); // Other device will be ahead in terms of nonce. So, we update nonce before generating share
      this.updateState({ currentEncPubKeyX: "" });
      this.updateState({ tKey: updatedTkey });
      const keys = await this.tryFinishTkeyReconstruction();
      await this.generateAndStoreNewDeviceShare();
      return keys;
    } finally {
      try {
        sentryTransaction?.finish();
      } catch (err) {
        log.error("Sentry error", err);
      }
    }
  }

  @Action
  async inputBackupShare(shareMnemonic: string): Promise<TKeyAccount[]> {
    const localTKey = await this.getTkeyInstance();
    const deserializedShare = await (localTKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).deserialize(
      shareMnemonic,
      "mnemonic"
    );
    await localTKey.inputShare(deserializedShare);
    const keys = await this.tryFinishTkeyReconstruction();
    await this.generateAndStoreNewDeviceShare();
    return keys;
  }

  @Action
  async inputPassword(password: string): Promise<TKeyAccount[]> {
    const localTKey = await this.getTkeyInstance();
    await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).inputShareFromSecurityQuestions(password);
    const keys = await this.tryFinishTkeyReconstruction();
    await this.generateAndStoreNewDeviceShare();
    return keys;
  }

  @Action
  async cleanUpShareTransfer(): Promise<void> {
    const localTKey = await this.getTkeyInstance();
    await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).cancelRequestStatusCheck();
    if (this.currentEncPubKeyX)
      await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).deleteShareTransferStore(this.currentEncPubKeyX);
  }

  @Action
  async changePassword(password: string): Promise<void> {
    const localTKey = await this.getTkeyInstance();
    await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).changeSecurityQuestionAndAnswer(password, PASSWORD_QUESTION);
    await localTKey.syncLocalMetadataTransitions();
    await this.calculateSettingsPageData();
    log.info("e2e:tests:changePasswordCompleted");
  }

  @Action
  async addPassword(password: string): Promise<void> {
    const localTKey = await this.getTkeyInstance();
    await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).generateNewShareWithSecurityQuestions(
      password,
      PASSWORD_QUESTION
    );
    await localTKey.syncLocalMetadataTransitions();
    await this.calculateSettingsPageData();
    await userModule.updateUserPersistedInfo({ payload: { tkey_password: true, tkey_threshold: this.keyDetails.totalShares } });
    log.info("e2e:tests:addPasswordCompleted");
  }

  @Action
  async copyShareUsingIndexAndStoreLocally(index: BN): Promise<void> {
    const localTKey = await this.getTkeyInstance();
    const outputshareStore = localTKey.outputShareStore(index);
    await (localTKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).storeDeviceShare(outputshareStore);
    await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
  }

  @Action
  async generateAndStoreNewDeviceShare(): Promise<void> {
    const localTKey = await this.getTkeyInstance();
    const newShare = await localTKey.generateNewShare();
    const customDeviceInfo = getCustomDeviceInfo();
    await (localTKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).storeDeviceShare(
      newShare.newShareStores[newShare.newShareIndex.toString("hex")],
      customDeviceInfo
    );
    await localTKey.syncLocalMetadataTransitions();
    this.updateState({ tKey: localTKey });
    await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
    const { aggregateVerifier: verifier, verifierId } = this.context.rootState[USER_MODULE_KEY].userInfo;
    const { verifierIDDeviceIDMap, devicePersistedInfo } = this.context.rootState[DEVICE_MODULE_KEY];

    const existingDeviceId = verifier && verifierId ? verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)] : undefined;
    const deviceInfoPayload = {
      device_id: existingDeviceId,
      share_index: newShare.newShareIndex.toString("hex"),
    };
    const finalDeviceInfoPayload = pickBy(deviceInfoPayload, (val) => val !== null && val !== undefined && val !== "");

    const infoAlreadyExist = isMatch(devicePersistedInfo[existingDeviceId], finalDeviceInfoPayload);
    if (!infoAlreadyExist) {
      if (existingDeviceId) {
        const resDeviceid = await updateDeviceInfo(finalDeviceInfoPayload);
        if (resDeviceid) {
          this.context.commit(
            `${DEVICE_MODULE_KEY}/setDevicePersistedInfo`,
            { deviceId: resDeviceid, info: { ...finalDeviceInfoPayload } },
            { root: true }
          );
        }
      }
    }
  }

  @Action
  async approveShareTransferRequest(encPubKeyX: string): Promise<void> {
    try {
      const localTKey = await this.getTkeyInstance();
      log.info("approving share transfer");
      await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).approveRequest(encPubKeyX);
      // await localTKey.syncShareMetadata();
    } catch (error) {
      log.error(error);
    } finally {
      this.startShareTransferRequestListener();
    }
  }

  @Action
  async denyShareTransferRequest(encPubKeyX: string): Promise<void> {
    try {
      log.info("deleting share transfer");
      const localTKey = await this.getTkeyInstance();
      await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).deleteShareTransferStore(encPubKeyX);
    } catch (error) {
      log.error(error);
    } finally {
      this.startShareTransferRequestListener();
    }
  }

  @Action
  async deleteShare(shareIndex: string): Promise<void> {
    const localTKey = await this.getTkeyInstance();
    await localTKey.deleteShare(shareIndex);
    await localTKey.syncLocalMetadataTransitions();
    if (this.settingsPageData.deviceShare.available) {
      const currentDeviceShare = this.settingsPageData.deviceShare?.share?.share.shareIndex.toString("hex");
      if (shareIndex === currentDeviceShare) {
        const { aggregateVerifier: verifier, verifierId } = this.context.rootState[USER_MODULE_KEY].userInfo;
        this.context.commit(`${DEVICE_MODULE_KEY}/clearVerifierDeviceId`, { verifier, verifierId }, { root: true });
        if (this.settingsPageData.webauthnDeviceShares[shareIndex]) {
          this.context.commit(
            `${DEVICE_MODULE_KEY}/setTouchIDPreference`,
            { verifier, verifierId, preference: TouchIDPreferences.UNSET },
            { root: true }
          );
        }
      }
    }

    await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
    const exportedSharesArr = Object.values(this.settingsPageData.exportedEmailShares);
    await Promise.all([
      deleteDevice(shareIndex, ""),
      userModule.updateUserPersistedInfo({
        payload: {
          tkey_backup_emails: exportedSharesArr.length,
          tkey_threshold: this.keyDetails.totalShares,
          backup_phrase_setup_at: exportedSharesArr.length ? exportedSharesArr[exportedSharesArr.length - 1].dateAdded : null,
          backup_phrase_setup_email: exportedSharesArr.length ? exportedSharesArr[exportedSharesArr.length - 1].email : null,
        },
      }),
    ]);
    log.info("e2e:tests:deleteShareCompleted");
  }

  @Action
  async exportShare(shareIndex: string): Promise<string> {
    const localTKey = await this.getTkeyInstance();
    const shareStore = localTKey.outputShareStore(shareIndex);
    const serializedShare = await (localTKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).serialize(
      shareStore.share.share,
      "mnemonic"
    );
    return serializedShare as string;
  }

  @Action
  async exportDeviceShare(): Promise<string> {
    const localTKey = await this.getTkeyInstance();
    const deviceShare = this.settingsPageData.deviceShare?.share?.share.share;
    if (!deviceShare) return "";
    const serializedShare = await (localTKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).serialize(deviceShare, "mnemonic");
    return serializedShare as string;
  }

  @Action
  async rehydrate(params: { postboxKey: string; tKeyJson: StringifiedType }): Promise<void> {
    const { postboxKey, tKeyJson } = params;
    await this._init({ postboxKey, tKeyJson, shareStores: [] });
    try {
      const localTKey = await this.getTkeyInstance();
      // In rehydration we always have privKey
      if (this.tKeyPrivKey && (this.keyMode === "v1" || this.keyMode === "2/n")) {
        log.info("rehydrating tkey");
        await localTKey.reconstructKey();
        this.updateState({ tKey: localTKey });
        if (window.self === window.top) this.startShareTransferRequestListener();
      }
    } catch (error) {
      log.warn(error, "maybe reconstruct only in certain times");
    }
    console.timeEnd("rehydrating tkey");
    log.info(`e2e:tests:tkeyjson:${JSON.stringify(tKeyJson).length}`);
  }

  @Action
  async login(params: {
    postboxKey: string;
    shareStores?: ShareStore[];
    tKeyJson?: ThresholdKey;
    deviceShareStore?: ShareStore;
    dappShare?: string;
  }): Promise<TKeyAccount[]> {
    try {
      const { postboxKey, shareStores, tKeyJson, deviceShareStore, dappShare } = params;
      // log.info(postboxKey, shareStores, "args to login");
      await this._init({ postboxKey, tKeyJson, shareStores: shareStores || [], dappShare });
      const { keyDetails, tKey, parsedShareDescriptions } = this;

      const { requiredShares: shareCount } = keyDetails;
      let requiredShares = shareCount;
      const descriptionBuffer = [];
      let currentIndex = 0;

      // window.addEventListener("beforeunload", beforeUnloadHandler);
      while (requiredShares > 0 && currentIndex < parsedShareDescriptions.length) {
        const currentShare = parsedShareDescriptions[currentIndex] as { module: string };
        currentIndex += 1;
        if (currentShare.module === WEB_STORAGE_MODULE_KEY) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).inputShareFromWebStorage();
            requiredShares -= 1;
          } catch (error) {
            log.warn(error, "unable to read share from device. Must be on other device");
            descriptionBuffer.push(currentShare);
          }
        } else if (currentShare.module === SECURITY_QUESTIONS_MODULE_KEY) {
          descriptionBuffer.push(currentShare);
        } else if (currentShare.module === CHROME_EXTENSION_STORAGE_MODULE_KEY) {
          // transfer share from other device
          descriptionBuffer.push(currentShare);
        }
      }

      const hexKeys = await this.tryFinishTkeyReconstruction();
      // only call this if reconstruction passes
      if (deviceShareStore) {
        try {
          const metadata = tKey.getMetadata();
          const tkeypubx = metadata.pubKey.x.toString("hex");

          // Back this up to webauthn
          const finalShareStore = tKey.outputShareStore(deviceShareStore.share.shareIndex.toString("hex"));
          if (finalShareStore.polynomialID !== deviceShareStore.polynomialID) {
            await storeShareOnLocalStorage(finalShareStore, tkeypubx);
            await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
          }
        } catch (error) {
          log.warn("share must already be deleted", error);
        }
      }
      log.info("e2e:tests:login completed");
      return hexKeys;
    } finally {
      // window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }

  @Action
  async tryFinishTkeyReconstruction(): Promise<TKeyAccount[]> {
    await this.calculateSettingsPageData();

    const { keyDetails: newDetails } = this;
    log.info("finishing tkey reconstruction");

    // Need input from UI
    if (newDetails.requiredShares > 0) {
      throw new Error(TKEY_REQUIRE_MORE_INPUT);
      // this.store.updateState({ showTKeyInput: true });
      // const tkeyJsonReturned = await this.tkeyInputFlow();
      // await this._rehydrate({ postboxKey, tkeyJsonReturned }s);
    } else {
      const { tKey: newTKey } = this;
      const { privKey } = await newTKey.reconstructKey(false);
      await this.calculateSettingsPageData({ forceCheckOnDeviceShare: true });
      await this.cleanUpShareTransfer();
      // this.startShareTransferRequestListener();

      const hexKeys = await getAllPrivateKeys(newTKey, privKey);
      if (hexKeys.length > 0) {
        this.updateState({ tKeyPrivKey: hexKeys[0].privKey });
        const { walletKeyInfo } = this.context.rootState[USER_MODULE_KEY];
        const userTkeyInfo: UpdateUserPayload = {
          tkey_public_address: this.tKeyPrivKey ? privateToAddress(getBufferFromHexKey(this.tKeyPrivKey)).toString("hex") : "",
          tkey_backup_emails: Object.values(this.settingsPageData.exportedEmailShares).length,
          tkey_threshold: this.keyDetails.totalShares,
          tkey_password: this.settingsPageData.passwordShare.available || null,
          wallet_public_address: walletKeyInfo.publicAddress,
        };
        await userModule.updateUserPersistedInfo({ payload: userTkeyInfo });
      }
      return hexKeys;
    }
  }
}

const tKeyModule = getModule(TKeyModule);

installStorePlugin({
  key: TKEY_MODULE_KEY,
  saveState: (key: string, state: Record<string, unknown>, storage?: Storage) => {
    storage?.setItem(key, JSON.stringify(state));
  },
  restoreState: (key: string, storage?: Storage) => {
    const value = storage?.getItem(key);
    if (typeof value === "string") {
      // If string, parse, or else, just return
      const parsedValue = JSON.parse(value || "{}");
      const { postboxKey, tKey, ...rest } = parsedValue[TKEY_MODULE_KEY] || {};
      console.time("rehydrating tkey");
      store.dispatch(`${TKEY_MODULE_KEY}/rehydrate`, { postboxKey, tKeyJson: tKey }, { root: true });
      const { clientTimeOffset = 0 } = parsedValue[USER_MODULE_KEY] || {};
      return {
        [TKEY_MODULE_KEY]: {
          ...rest,
          postboxKey,
          tKey: new ThresholdKey({ manualSync: true, serverTimeOffset: clientTimeOffset }),
          initialized: false,
        },
      };
    }
    return value || {};
  },
  storage: SESSION_STORAGE_KEY,
});

export default tKeyModule;
