import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import iconsValues from "../icons";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#0364FF",
        primary2: "#1AC7FE",
        text1: "#25282B",
        text2: "#5C6C7F",
        text3: "#676767",
        textButton: "#FFFFFF",
        grey1: "#BDC2CB",
        grey2: "#7F8FA4",
        grey3: "#6F717A",
        grey4: "#F4F4F4",
        grey5: "#EDEDED",
        background1: "#FFFFFF",
        background2: "#FFFFFF",
        background3: "#F9F9FB",
        success: "#30CCA4",
        warning: "#FBC94A",
        error: "#FB4A61",
      },
      dark: {
        primary: "#0364FF",
        primary2: "#1AC7FE",
        text1: "#F9F9FB",
        text2: "#FBF7F3",
        text3: "#D5D5D5",
        textButton: "#FFFFFF",
        grey1: "#BDC2CB",
        grey2: "#7F8FA4",
        grey3: "#6F717A",
        grey4: "#F4F4F4",
        grey5: "#414140",
        background1: "#252529",
        background2: "#404040",
        background3: "#404040",
        success: "#30CCA4",
        warning: "#FBC94A",
        error: "#FB4A61",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    values: iconsValues,
  },
});
