import { isMatch, pickBy } from "lodash-es";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { updateDeviceInfo } from "@/graphql/mutations";
import { fetchDeviceQuery } from "@/graphql/operations/__generated__/device";
import { DEVICE_MODULE_KEY, LOCAL_STORAGE_KEY } from "@/utils/enums";
import { TouchIDPreferences, TouchIDPreferencesType } from "@/utils/interfaces";
import { canPreserveState, getJoinedKey } from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";

export type CredIDMapCache = {
  [key: string]: {
    confirmed: boolean;
    credIds: string[];
    transports?: AuthenticatorTransport[];
  };
};

type TouchIDVerifierIDMap = {
  [key: string]: TouchIDPreferencesType;
};

type TouchIDOnboardingVerifierIDMap = {
  [key: string]: boolean;
};

type VerifierIDDeviceIDMap = {
  [key: string]: string;
};

@Module({
  namespaced: true,
  name: DEVICE_MODULE_KEY,
  store,
  dynamic: true,
  preserveState: canPreserveState(DEVICE_MODULE_KEY, LOCAL_STORAGE_KEY),
})
class DeviceModule extends VuexModule {
  public isTouchIDRegistered = false;

  public lastLoggedInVerifier = "";

  public verifierToLastLoggedInVerifierID: Record<string, string> = {};

  public touchIDVerifierIDMap: TouchIDVerifierIDMap = {};

  public onboardingMap: TouchIDOnboardingVerifierIDMap = {};

  public walletTKeyOnboardingMap: TouchIDOnboardingVerifierIDMap = {};

  public credIdCache: string[] = []; // deprecated, but do not remove for backward compatibility reasons

  public credIdMapCache: CredIDMapCache = {};

  public verifierIDDeviceIDMap: VerifierIDDeviceIDMap = {};

  public devicePersistedInfo: Record<string, fetchDeviceQuery["devices"][0]> = {};

  @Mutation
  public setDevicePersistedInfo(params: { deviceId: string; info: Partial<fetchDeviceQuery["devices"][0]> }): void {
    this.devicePersistedInfo = { ...this.devicePersistedInfo, [params.deviceId]: { ...this.devicePersistedInfo[params.deviceId], ...params.info } };
  }

  @Mutation
  public setVerifierDeviceId(params: { verifier: string; verifierId: string; deviceId: string }): void {
    const { verifier, verifierId, deviceId } = params;

    const key = getJoinedKey(verifier, verifierId);
    this.verifierIDDeviceIDMap = { ...this.verifierIDDeviceIDMap, [key]: deviceId };
  }

  @Mutation
  public clearVerifierDeviceId(params: { verifier: string; verifierId: string }): void {
    const { verifier, verifierId } = params;
    this.verifierIDDeviceIDMap = { ...this.verifierIDDeviceIDMap, [getJoinedKey(verifier, verifierId)]: "" };
  }

  @Mutation
  public setTouchIDRegistered(): void {
    this.isTouchIDRegistered = true;
  }

  @Mutation
  public setTouchIDPreferenceState(params: { verifier: string; verifierId: string; preference: TouchIDPreferencesType }) {
    const { verifier, verifierId, preference } = params;
    this.touchIDVerifierIDMap = { ...this.touchIDVerifierIDMap, [getJoinedKey(verifier, verifierId)]: preference };
  }

  @Action
  async setTouchIDPreference(params: { verifier: string; verifierId: string; preference: TouchIDPreferencesType }): Promise<void> {
    const { verifier, verifierId, preference } = params;
    this.setTouchIDPreferenceState(params);
    const deviceId = this.verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)];
    if (deviceId) {
      const isEnabled = preference === TouchIDPreferences.ENABLED;
      const isSet = preference !== TouchIDPreferences.UNSET;
      const deviceInfoPayload = { device_id: deviceId, webauthn_enabled: isEnabled, webauthn_available: isSet };
      const finalDeviceInfoPayload = pickBy(deviceInfoPayload, (val) => val !== null && val !== undefined && val !== "");

      const infoAlreadyExist = isMatch(this.devicePersistedInfo[deviceId], finalDeviceInfoPayload);
      if (!infoAlreadyExist) {
        const returnedDeviceId = await updateDeviceInfo(finalDeviceInfoPayload);
        if (returnedDeviceId) {
          this.setDevicePersistedInfo({ deviceId: returnedDeviceId, info: { ...finalDeviceInfoPayload } });
        }
      }
    }
  }

  @Mutation
  public setTouchIDOnboarding(params: { verifier: string; verifierId: string; onboarding: boolean }): void {
    this.onboardingMap = { ...this.onboardingMap, [getJoinedKey(params.verifier, params.verifierId)]: params.onboarding };
  }

  @Mutation
  public setWalletTKeyOnboarding(params: { verifier: string; verifierId: string; onboarding: boolean }): void {
    this.walletTKeyOnboardingMap = { ...this.walletTKeyOnboardingMap, [getJoinedKey(params.verifier, params.verifierId)]: params.onboarding };
  }

  @Mutation
  public setLastLoggedInVerifier(verifier: string): void {
    this.lastLoggedInVerifier = verifier;
  }

  @Mutation
  public setLastLoggedIn(params: { verifier: string; verifierId: string }): void {
    this.verifierToLastLoggedInVerifierID = { ...this.verifierToLastLoggedInVerifierID, [params.verifier]: params.verifierId };
  }

  @Mutation
  public addCredId(params: {
    verifier: string;
    verifierId: string;
    credId: string;
    confirmed: boolean;
    transports?: AuthenticatorTransport[];
  }): void {
    const { verifier, verifierId, credId, confirmed, transports } = params;
    const joinedKey = getJoinedKey(verifier, verifierId);
    if (!this.credIdMapCache[joinedKey]) {
      this.credIdMapCache[joinedKey] = {
        confirmed: false,
        credIds: [],
        transports,
      };
    }
    if (confirmed) {
      this.credIdMapCache[joinedKey] = {
        confirmed: true,
        credIds: [credId],
        transports,
      };
    } else if (!this.credIdMapCache[joinedKey].confirmed) {
      if (!this.credIdMapCache[joinedKey].credIds.includes(credId)) {
        this.credIdMapCache[joinedKey].credIds.push(credId);
      }
    } else {
      // if we already have a credId that is confirmed, we ignore
    }
  }
}

const deviceModule = getModule(DeviceModule);

installStorePlugin({ key: DEVICE_MODULE_KEY, storage: LOCAL_STORAGE_KEY });

export default deviceModule;
