import Vue from "vue";
import Vuex, { MutationPayload, Store } from "vuex";
import { config } from "vuex-module-decorators";
import createMutationsSharer from "vuex-shared-mutations";

import configuration from "./config";
import { DAPP_MODULES_STORE_KEY, LOCAL_STORAGE_KEY, LOGIN_PERF_MODULE_KEY, TKEY_MODULE_KEY, USER_MODULE_KEY } from "./utils/enums";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

Vue.use(Vuex);

const pluginsArray = [];

if (configuration.isStorageAvailable[LOCAL_STORAGE_KEY]) {
  pluginsArray.push(
    createMutationsSharer({
      predicate: (mutation: MutationPayload) => {
        // conditions may change in future. hence, structure like this
        if (
          mutation.type.includes(TKEY_MODULE_KEY) ||
          mutation.type.includes(USER_MODULE_KEY) ||
          mutation.type.includes(DAPP_MODULES_STORE_KEY) ||
          mutation.type.includes(LOGIN_PERF_MODULE_KEY) ||
          mutation.type.includes(USER_MODULE_KEY)
        ) {
          return false;
        }
        return true;
      },
    })
  );
}

const store = new Store({
  plugins: pluginsArray,
});

export default store;
