import { LogLevelDesc } from "loglevel";

function getCurrentLogLevel() {
  let logLevel: LogLevelDesc;
  switch (process.env.VUE_APP_TORUS_BUILD_ENV) {
    case "production":
    case "aqua":
    case "cyan":
    case "celeste":
    case "staging":
      logLevel = "error";
      break;
    default:
      logLevel = "debug";
      break;
  }
  return logLevel;
}

export default getCurrentLogLevel;
