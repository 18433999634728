import { SECURITY_QUESTIONS_MODULE_NAME } from "@tkey/security-questions";
import { SHARE_SERIALIZATION_MODULE_NAME } from "@tkey/share-serialization";
import { SHARE_TRANSFER_MODULE_NAME } from "@tkey/share-transfer";
import { WEB_STORAGE_MODULE_NAME } from "@tkey/web-storage";

export const WEB_STORAGE_MODULE_KEY = WEB_STORAGE_MODULE_NAME;
export const SECURITY_QUESTIONS_MODULE_KEY = SECURITY_QUESTIONS_MODULE_NAME;
export const SHARE_SERIALIZATION_MODULE_KEY = SHARE_SERIALIZATION_MODULE_NAME;
export const CHROME_EXTENSION_STORAGE_MODULE_KEY = "chromeExtensionStorage";
export const SHARE_TRANSFER_MODULE_KEY = SHARE_TRANSFER_MODULE_NAME;
export const WEBAUTHN_DEVICE_MODULE_KEY = "webauthnDevice";
export const PASSWORD_QUESTION = "what is your password?";

export const TKEY_REQUIRE_MORE_INPUT = "requires more input";
export const TKEY_SHARE_TRANSFER_INTERVAL = 5000;
export const ERROR_TIME = 5 * 1000;

export const GOOGLE = "google";
export const FACEBOOK = "facebook";
export const REDDIT = "reddit";
export const DISCORD = "discord";
export const TWITCH = "twitch";
export const APPLE = "apple";
export const LINE = "line";
export const GITHUB = "github";
export const LINKEDIN = "linkedin";
export const TWITTER = "twitter";
export const WEIBO = "weibo";
export const JWT = "jwt";
export const WEBAUTHN = "webauthn";

export const GOOGLE_LOGIN_PROVIDER = "google";
export const FACEBOOK_LOGIN_PROVIDER = "facebook";
export const REDDIT_LOGIN_PROVIDER = "reddit";
export const DISCORD_LOGIN_PROVIDER = "discord";
export const TWITCH_LOGIN_PROVIDER = "twitch";
export const APPLE_LOGIN_PROVIDER = "apple";
export const LINE_LOGIN_PROVIDER = "line";
export const GITHUB_LOGIN_PROVIDER = "github";
export const KAKAO_LOGIN_PROVIDER = "kakao";
export const LINKEDIN_LOGIN_PROVIDER = "linkedin";
export const TWITTER_LOGIN_PROVIDER = "twitter";
export const WEIBO_LOGIN_PROVIDER = "weibo";
export const WECHAT_LOGIN_PROVIDER = "wechat";
export const EMAIL_PASSWORDLESS_LOGIN_PROVIDER = "email_passwordless";
export const WEBAUTHN_LOGIN_PROVIDER = "webauthn";
export const SMS_PASSWORDLESS_LOGIN_PROVIDER = "sms_passwordless";

export const ERROR_MISSING_PARAMS = "Missing params";
export const OPENLOGIN_DAPP_MODULE_KEY = "openlogin";
export const OPENLOGIN_DAPP_MODULE_SUFFIX = "-openlogin-dapp";

export const LOCAL_STORAGE_KEY = "localStorage";
export const SESSION_STORAGE_KEY = "sessionStorage";
export type STORAGE_TYPE = typeof LOCAL_STORAGE_KEY | typeof SESSION_STORAGE_KEY;

export const USER_MODULE_KEY = "userModule";
export const LOGIN_PERF_MODULE_KEY = "loginPerfModule";
export const DEVICE_MODULE_KEY = "deviceModule";
export const TKEY_MODULE_KEY = "tKeyModule";
export const PID_MODULE_KEY = "pidModule";
export const DAPP_MODULES_STORE_KEY = "dappModulesStore";
export const STORE_MODULES = [USER_MODULE_KEY, LOGIN_PERF_MODULE_KEY, DEVICE_MODULE_KEY, TKEY_MODULE_KEY, PID_MODULE_KEY, DAPP_MODULES_STORE_KEY];
export const OAUTH_CREDID_CACHE = "oauth_credid_cache";
export const OAUTH_USERINFO = "oauth_userinfo";

export const OPENLOGIN_WALLET_MIGRATION = "openlogin_wallet_migration";

export const OPENLOGIN_METHOD = {
  LOGIN: "openlogin_login",
  LOGOUT: "openlogin_logout",
  CHECK_3PC_SUPPORT: "openlogin_check_3PC_support",
  SET_PID_DATA: "openlogin_set_pid_data",
  GET_DATA: "openlogin_get_data",
  PROMPT: "openlogin_prompt",
};

export type OPENLOGIN_METHOD_TYPE = (typeof OPENLOGIN_METHOD)[keyof typeof OPENLOGIN_METHOD];

export const LOCALE_EN = "en";
export const LOCALE_DE = "de";
export const LOCALE_JA = "ja";
export const LOCALE_KO = "ko";
export const LOCALE_ZH = "zh";
export const LOCALE_ES = "es";
export const LOCALE_EN_LABEL = "English";
export const LOCALE_DE_LABEL = "German (Deutsch)";
export const LOCALE_JA_LABEL = "Japanese (日本語)";
export const LOCALE_KO_LABEL = "Korean (한국어)";
export const LOCALE_ZH_LABEL = "Mandarin (中文)";
export const LOCALE_ES_LABEL = "Spanish (Español)";

export const LOCALES = [
  {
    name: LOCALE_EN_LABEL,
    value: LOCALE_EN,
  },
  {
    name: LOCALE_DE_LABEL,
    value: LOCALE_DE,
  },
  {
    name: LOCALE_JA_LABEL,
    value: LOCALE_JA,
  },
  {
    name: LOCALE_KO_LABEL,
    value: LOCALE_KO,
  },
  {
    name: LOCALE_ZH_LABEL,
    value: LOCALE_ZH,
  },
  {
    name: LOCALE_ES_LABEL,
    value: LOCALE_ES,
  },
];

export const TOAST_TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const AUTH_OP = "@auth";
export const USER_REGISTERATION_OP = "@user_registeration";
export const TKEY_LOOKUP_OP = "@tkey_lookup";
export const CHECK_IF_TKEY_EXIST = "@check_if_tkey_exist";
export const TKEY_LOGIN_OP = "@tkey_login";
export const PREFERENCES_OP = "@set_preferences";
export const GET_WALLET_KEY = "@get_wallet_key";
export const SET_TORUS_SHARE = "@set_torus_share";
export const UPDATE_USER_INFO = "@update_user_info";
export const OAUTH_SHARE = "@oauth_share";

export const REGISTER_MFA_OP = "@registerv2/mfaOps";
export const REGISTER_RECOVERY_EMAIL_OP = "@register/registerRecoveryEmail";
export const REGISTER_ACCOUNT_PASSWORD_OP = "@register/registerAccountPassword";
export const START_PAGE_OP = "@start";
export const TKEY_SHARE_TRANSFER_OP = "@tkeyInput/listenForShareTransfer";
export const TKEY_CONFIRM_BACKUP_OP = "@tkeyInput/confirmBackUpPhrase";
export const TKEY_ADD_PASSWORD_OP = "@tkeyInput/addPassword";

export const AUTH_FACTOR = {
  SHARE_TRANSFER: "share-transfer",
  BACKUP_PHRASE: "backup-phrase",
  PASSWORD: "password",
  DEVICE: "device",
};
export type AUTH_FACTOR_TYPE = (typeof OPENLOGIN_METHOD)[keyof typeof OPENLOGIN_METHOD];

export const DISCORD_ACCESS_DENIED_ERROR = "The+resource+owner+or+authorization+server+denied+the+request";

export const WEBAUTHN_DENIAL_ERROR = "User has denied authorization";
