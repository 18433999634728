import AlertCircleIcon from "./AlertCircleIcon.vue";
import AppsIcon from "./AppsIcon.vue";
import BiometricIcon from "./BiometricIcon.vue";
import BrowserAndroidIcon from "./BrowserAndroidIcon.vue";
import BrowserBraveIcon from "./BrowserBraveIcon.vue";
import BrowserChromeIcon from "./BrowserChromeIcon.vue";
import BrowserEdgeIcon from "./BrowserEdgeIcon.vue";
import BrowserFirefoxIcon from "./BrowserFirefoxIcon.vue";
import BrowserIcon from "./BrowserIcon.vue";
import BrowserSafariIcon from "./BrowserSafariIcon.vue";
import CheckboxChecked from "./CheckboxChecked.vue";
import CheckboxUnchecked from "./CheckboxUnchecked.vue";
import CheckCircleFilledIcon from "./CheckCircleFilledIcon.vue";
import CheckCircleIcon from "./CheckCircleIcon.vue";
import CloseCircleIcon from "./CloseCircleIcon.vue";
import CloseIcon from "./CloseIcon.vue";
import CloseMiniIcon from "./CloseMiniIcon.vue";
import CopyIcon from "./CopyIcon.vue";
import DeviceDesktopIcon from "./DeviceDesktopIcon.vue";
import DeviceMobileIcon from "./DeviceMobileIcon.vue";
import DeviceTabletIcon from "./DeviceTabletIcon.vue";
import DownloadIcon from "./DownloadIcon.vue";
import FileLockIcon from "./FileLockIcon.vue";
import GlobeIcon from "./GlobeIcon.vue";
import HomeIcon from "./HomeIcon.vue";
import InfoCircleIcon from "./InfoCircleIcon.vue";
import KeyIcon from "./KeyIcon.vue";
import LangIcon from "./LangIcon.vue";
import LockFilledIcon from "./LockFilledIcon.vue";
import LogoutIcon from "./LogoutIcon.vue";
import MailIcon from "./MailIcon.vue";
import MenuIcon from "./MenuIcon.vue";
import MnemonicIcon from "./MnemonicIcon.vue";
import SelectIcon from "./SelectIcon.vue";
import SendIcon from "./SendIcon.vue";
import ShieldLockIcon from "./ShieldLockIcon.vue";
import ShieldOkIcon from "./ShieldOkIcon.vue";
import TrashIcon from "./TrashIcon.vue";
import UserIcon from "./UserIcon.vue";
import VerifierAppleIcon from "./VerifierAppleIcon.vue";
import VerifierDiscordIcon from "./VerifierDiscordIcon.vue";
import VerifierEmailIcon from "./VerifierEmailIcon.vue";
import VerifierFacebookIcon from "./VerifierFacebookIcon.vue";
import VerifierGithubIcon from "./VerifierGithubIcon.vue";
import VerifierGoogleIcon from "./VerifierGoogleIcon.vue";
import VerifierJwtIcon from "./VerifierJwtIcon.vue";
import VerifierKakaoIcon from "./VerifierKakaoIcon.vue";
import VerifierLineIcon from "./VerifierLineIcon.vue";
import VerifierLinkedinIcon from "./VerifierLinkedinIcon.vue";
import VerifierPasswordlessIcon from "./VerifierPasswordlessIcon.vue";
import VerifierPhoneIcon from "./VerifierPhoneIcon.vue";
import VerifierRedditIcon from "./VerifierRedditIcon.vue";
import VerifierTwitchIcon from "./VerifierTwitchIcon.vue";
import VerifierTwitterIcon from "./VerifierTwitterIcon.vue";
import VerifierWebAuthn from "./VerifierWebAuthn.vue";
import VerifierWechatIcon from "./VerifierWechatIcon.vue";
import VerifierWeiboIcon from "./VerifierWeiboIcon.vue";
import VisibilityOffIcon from "./VisibilityOffIcon.vue";
import VisibilityOnIcon from "./VisibilityOnIcon.vue";
import WalletIcon from "./WalletIcon.vue";
import WebauthnGeneric from "./WebauthnGeneric.vue";

export default {
  apps: {
    component: AppsIcon,
  },
  biometric: {
    component: BiometricIcon,
  },
  browser: {
    component: BrowserIcon,
  },
  browser_android: {
    component: BrowserAndroidIcon,
  },
  browser_chrome: {
    component: BrowserChromeIcon,
  },
  browser_edge: {
    component: BrowserEdgeIcon,
  },
  browser_microsoft_edge: {
    component: BrowserEdgeIcon,
  },
  browser_firefox: {
    component: BrowserFirefoxIcon,
  },
  browser_safari: {
    component: BrowserSafariIcon,
  },
  browser_brave: {
    component: BrowserBraveIcon,
  },
  checkbox_checked: {
    component: CheckboxChecked,
  },
  checkbox_unchecked: {
    component: CheckboxUnchecked,
  },
  check_circle_filled: {
    component: CheckCircleFilledIcon,
  },
  check_circle: {
    component: CheckCircleIcon,
  },
  device_desktop: {
    component: DeviceDesktopIcon,
  },
  device_mobile: {
    component: DeviceMobileIcon,
  },
  device_tablet: {
    component: DeviceTabletIcon,
  },
  home: {
    component: HomeIcon,
  },
  lock_filled: {
    component: LockFilledIcon,
  },
  logout: {
    component: LogoutIcon,
  },
  mail: {
    component: MailIcon,
  },
  menu: {
    component: MenuIcon,
  },
  discord: {
    component: VerifierDiscordIcon,
  },
  facebook: {
    component: VerifierFacebookIcon,
  },
  google: {
    component: VerifierGoogleIcon,
  },
  reddit: {
    component: VerifierRedditIcon,
  },
  twitch: {
    component: VerifierTwitchIcon,
  },
  github: {
    component: VerifierGithubIcon,
  },
  kakao: {
    component: VerifierKakaoIcon,
  },
  twitter: {
    component: VerifierTwitterIcon,
  },
  webauthn: {
    component: VerifierWebAuthn,
  },
  webauthn_generic: {
    component: WebauthnGeneric,
  },
  wechat: {
    component: VerifierWechatIcon,
  },
  weibo: {
    component: VerifierWeiboIcon,
  },
  linkedin: {
    component: VerifierLinkedinIcon,
  },
  email_password: {
    component: VerifierEmailIcon,
  },
  email_passwordless: {
    component: VerifierEmailIcon,
  },
  // TODO: need to update this icon.
  sms_passwordless: {
    component: VerifierPhoneIcon,
  },
  jwt: {
    component: VerifierJwtIcon,
  },
  email: {
    component: VerifierPasswordlessIcon,
  },
  apple: {
    component: VerifierAppleIcon,
  },
  line: {
    component: VerifierLineIcon,
  },
  select: {
    component: SelectIcon,
  },
  send: {
    component: SendIcon,
  },
  user: {
    component: UserIcon,
  },
  visibility_on: {
    component: VisibilityOnIcon,
  },
  visibility_off: {
    component: VisibilityOffIcon,
  },
  wallet: {
    component: WalletIcon,
  },
  trash: {
    component: TrashIcon,
  },
  download: {
    component: DownloadIcon,
  },
  shield_lock: {
    component: ShieldLockIcon,
  },
  shield_ok: {
    component: ShieldOkIcon,
  },
  mnemonic: {
    component: MnemonicIcon,
  },
  copy: {
    component: CopyIcon,
  },
  close: {
    component: CloseIcon,
  },
  close_mini: {
    component: CloseMiniIcon,
  },
  close_circle: {
    component: CloseCircleIcon,
  },
  alert_circle: {
    component: AlertCircleIcon,
  },
  info_circle: {
    component: InfoCircleIcon,
  },
  globe: {
    component: GlobeIcon,
  },
  lang: {
    component: LangIcon,
  },
  key: {
    component: KeyIcon,
  },
  file_lock: {
    component: FileLockIcon,
  },
};
