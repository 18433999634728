
import copyToClipboard from "copy-to-clipboard";
import { mixins } from "vue-class-component";
// import log from "loglevel";
import { Component } from "vue-property-decorator";

import { userModule } from "@/store";
import TranslationMixin from "@/TranslationMixin";
import { ErrorTraceObj } from "@/utils/interfaces";

@Component
export default class Trace extends mixins(TranslationMixin) {
  dialog = false;

  copied = false;

  get errorTrace(): ErrorTraceObj[] {
    return userModule.errorTraceMessageList;
  }

  get showErrorTraceModalButton(): boolean {
    return this.errorTrace.length > 0;
  }

  // @Watch("errorTrace", { immediate: false })
  // onErrorTraceChanged(newVal: boolean): void {
  //   if (newVal && this.errorTrace.length > 0) {
  //     this.dialog = true;
  //   }
  // }

  clearTrace(): void {
    userModule.clearErrorTraceMessages();
    this.dialog = false;
  }

  copyToClip(message?: ErrorTraceObj): void {
    if (!message) this.copied = true;
    copyToClipboard(JSON.stringify(message || this.errorTrace));
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }
}
