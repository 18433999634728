import type { Transaction } from "@sentry/types";
import * as Sentry from "@sentry/vue";
import { getPublic } from "@toruslabs/eccrypto";
import bowser from "bowser";
import { isMatch, pickBy } from "lodash-es";
import log from "loglevel";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { addUserLoginDetails, fetchDeviceById, updateDeviceInfo, updateUserLoginDetails } from "@/graphql/mutations";
import { SkipSync } from "@/store/skipSync";
import { UpdateLoginState, UserLoginPayload } from "@/utils/__generated__/graphql-types";
import { AUTH_FACTOR_TYPE, DEVICE_MODULE_KEY, LOGIN_PERF_MODULE_KEY, SESSION_STORAGE_KEY, TKEY_MODULE_KEY, USER_MODULE_KEY } from "@/utils/enums";
import { LoginType } from "@/utils/interfaces";
import {
  canPreserveState,
  getBufferFromHexKey,
  getCustomDeviceInfo,
  getJoinedKey,
  measurePerformance,
  measurePerformanceAndRestart,
  sanitizeUrl,
} from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";

@Module({
  namespaced: true,
  name: LOGIN_PERF_MODULE_KEY,
  store,
  dynamic: true,
  preserveState: canPreserveState(LOGIN_PERF_MODULE_KEY, SESSION_STORAGE_KEY),
})
class LoginPerfModule extends VuexModule {
  @SkipSync(LOGIN_PERF_MODULE_KEY)
  sentryTx: null | Transaction = null;

  public currentLoginPath = "";

  public lastRoute = "";

  public totalTimeTaken = 0;

  public totalMfaTimeTaken = 0;

  public loginRecordId = "";

  public dappId = 0;

  public authFactorsUsed: AUTH_FACTOR_TYPE[] = [];

  @Mutation
  _reinit() {
    this.currentLoginPath = "";
    this.loginRecordId = "";
    this.lastRoute = "";
    this.totalMfaTimeTaken = 0;
  }

  @Mutation
  resetDappId() {
    this.dappId = 0;
    this.authFactorsUsed = [];
    this.sentryTx = null;
  }

  @Mutation
  incrementTotalTime(timeTaken: number) {
    this.totalTimeTaken += timeTaken;
  }

  @Mutation
  incrementTotalMfaTime(operationName: string) {
    const timeTaken = measurePerformance(operationName);
    this.totalTimeTaken += timeTaken;
    this.totalMfaTimeTaken += timeTaken;
  }

  @Mutation
  public markRouteAndTime(params: { route: string; restartPerfMeasurement?: boolean; isEnd?: boolean; operation?: string }) {
    const { route, isEnd, operation, restartPerfMeasurement } = params;
    let timeTaken = 0;
    if (restartPerfMeasurement && operation) {
      timeTaken = measurePerformanceAndRestart(operation);
    } else if (operation) {
      timeTaken = measurePerformance(operation);
    }
    if (this.lastRoute !== route) {
      this.currentLoginPath = this.currentLoginPath ? `${this.currentLoginPath}|${route}` : route;
    }
    this.lastRoute = route;
    this.totalTimeTaken += timeTaken;

    try {
      if (!this.sentryTx) {
        this.sentryTx = Sentry.startTransaction({ name: "login" });
      }

      if (this.sentryTx && operation) {
        const childSpan = this.sentryTx.startChild({
          op: operation,
          startTimestamp: Math.floor(Date.now() / 1000) - Math.floor(timeTaken / 1000),
        });
        childSpan.finish(Math.floor(Date.now() / 1000));
      }

      if (isEnd && this.sentryTx) {
        const endTime = Math.floor(this.sentryTx.startTimestamp) + Math.floor(this.totalTimeTaken / 1000);
        if (this.sentryTx.finish) {
          this.sentryTx.finish(endTime);
        }
        this.sentryTx = null;
        this.totalTimeTaken = 0;
      }
    } catch (error) {
      log.error("error while recording perf in sentry", error);
    }
  }

  @Mutation
  addAuthFactor(authFactor: AUTH_FACTOR_TYPE) {
    if (this.authFactorsUsed.indexOf(authFactor) < 0) this.authFactorsUsed.push(authFactor);
  }

  @Action
  async addLoginRecord(params: {
    clientId: string;
    dappUrl: string;
    loginRoute: string;
    sessionId: string;
    walletAddress?: string;
    verifierId?: string;
    verifier?: string;
    webauthnEnabled?: boolean;
    errorStack?: string;
    hasSkippedTkey?: boolean;
    isWebauthnLogin?: boolean;
    shareIndex?: string;
    isLoginCompleted?: boolean;
    loginType: LoginType;
    fetchLoginCount?: boolean;
    mfaLevel?: string;
    mobileOrigin?: string;
  }): Promise<number> {
    const {
      verifier,
      verifierId,
      walletAddress,
      errorStack,
      hasSkippedTkey,
      loginRoute,
      dappUrl,
      clientId,
      isLoginCompleted,
      webauthnEnabled,
      isWebauthnLogin,
      shareIndex,
      loginType,
      fetchLoginCount,
      mfaLevel,
      mobileOrigin,
      sessionId,
    } = params;

    const { authToken } = this.context.rootState[USER_MODULE_KEY];
    if (!authToken) {
      return 0;
    }

    if (clientId && dappUrl && loginRoute) {
      const webauthnAvailable = window.PublicKeyCredential && (await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable());
      const hostname = sanitizeUrl(dappUrl).host;
      const browser = bowser.getParser(window.navigator.userAgent);
      const specialBrowser = getCustomDeviceInfo();

      const payload: UserLoginPayload = {
        client_id: clientId,
        hostname,
        webauthn_available: webauthnAvailable,
        login_route: this.currentLoginPath || loginRoute,
        time_taken: this.totalTimeTaken,
        os: browser.getOSName(),
        os_version: browser.getOSVersion(),
        browser: specialBrowser?.browser || browser.getBrowserName(),
        browser_version: browser.getBrowserVersion(),
        platform: browser.getPlatform().type || "desktop",
        login_type: loginType,
        fetch_login_count: fetchLoginCount,
        mfa_level: mfaLevel || "",
        factors_used: this.authFactorsUsed.join("|"),
        mobile_origin: mobileOrigin,
      };

      const deviceShareIndex =
        this.context.rootState[TKEY_MODULE_KEY].settingsPageData?.deviceShare?.share?.share.shareIndex.toString("hex") || shareIndex || undefined;

      if (deviceShareIndex) payload.share_index = deviceShareIndex;
      const existingDeviceId =
        verifier && verifierId ? this.context.rootState[DEVICE_MODULE_KEY].verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)] : undefined;

      // if some existing device id found for current verifier,
      // then search for share index if not exist.
      if (!payload.share_index && existingDeviceId) {
        const existingDevice =
          this.context.rootState[DEVICE_MODULE_KEY].devicePersistedInfo[existingDeviceId] || (await fetchDeviceById(existingDeviceId));
        if (existingDevice && existingDevice.share_index) payload.share_index = existingDevice.share_index;
      }
      payload.wallet_public_address = walletAddress;
      payload.error_stack = errorStack;
      payload.webauthn_enabled = !!webauthnEnabled;
      payload.is_fast_login = isWebauthnLogin;
      payload.has_skipped_tkey = hasSkippedTkey;
      payload.session_pub_key = getPublic(getBufferFromHexKey(sessionId)).toString("hex");

      if (loginType === "1/1" && existingDeviceId) {
        payload.device_id = existingDeviceId;
      }

      const { loginRecordId, deviceId, dappId, loginCount } = await addUserLoginDetails(payload);
      if (loginRecordId && deviceId && dappId) {
        this.updateState({ loginRecordId, dappId });
        // in case of error verifier and verifier id might not be passed but we have recorded
        // the error irrespective of that above.
        if (verifier && verifierId)
          this.context.commit(`${DEVICE_MODULE_KEY}/setVerifierDeviceId`, { verifier, verifierId, deviceId }, { root: true });
      }
      if (isLoginCompleted || hasSkippedTkey) {
        this._reinit();
      }
      return loginCount || 0;
    }
    return 0;
  }

  @Action
  async updateDeviceIndex({ verifier, verifierId, deviceShareIndex }: { verifier: string; verifierId: string; deviceShareIndex: string }) {
    const { verifierIDDeviceIDMap, devicePersistedInfo } = this.context.rootState[DEVICE_MODULE_KEY];
    const existingDeviceId = verifier && verifierId ? verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)] : undefined;
    const payload = {
      device_id: existingDeviceId,
      share_index: deviceShareIndex,
    };
    const finalPayload = pickBy(payload, (val) => val !== null && val !== undefined && val !== "");

    const infoAlreadyExist = isMatch(devicePersistedInfo[existingDeviceId], finalPayload);
    if (!infoAlreadyExist) {
      const finalDeviceId = await updateDeviceInfo(payload);
      if (finalDeviceId) {
        this.context.commit(`${DEVICE_MODULE_KEY}/setVerifierDeviceId`, { verifier, verifierId, deviceId: finalDeviceId }, { root: true });
        this.context.commit(`${DEVICE_MODULE_KEY}/setDevicePersistedInfo`, { deviceId: finalDeviceId, info: { ...payload } }, { root: true });
      }
    }
  }

  @Action
  async UpdateLoginRecord(params: {
    verifierId?: string;
    verifier?: string;
    shareIndex?: string;
    errorStack?: string;
    hasSkippedTkey?: boolean;
    isLoginCompleted?: boolean;
    loginType?: LoginType;
    hasSkippedMfa?: boolean;
    mfaLevel?: string;
    mobileOrigin?: string;
  }): Promise<void> {
    const { authToken } = this.context.rootState[USER_MODULE_KEY];
    if (!authToken) {
      return;
    }

    if (!this.loginRecordId || !this.dappId) {
      log.error("loginRecordId doesn't exist, create a login record first.");
      return;
    }
    const { shareIndex, verifier, verifierId, errorStack, hasSkippedTkey, isLoginCompleted, loginType, hasSkippedMfa, mfaLevel, mobileOrigin } =
      params;
    const walletAddress = this.context.rootState[USER_MODULE_KEY].walletKeyInfo.publicAddress;

    const payload: UpdateLoginState = {
      login_record_id: this.loginRecordId,
      login_route: this.currentLoginPath,
      time_taken: this.totalTimeTaken,
      wallet_public_address: walletAddress,
      login_type: loginType as LoginType,
      has_skipped_mfa: hasSkippedMfa,
      has_skipped_tkey: hasSkippedTkey,
      error_stack: errorStack,
      mfa_level: mfaLevel || "",
      factors_used: this.authFactorsUsed.join("|"),
      mobile_origin: mobileOrigin,
    };
    if (isLoginCompleted || hasSkippedTkey || hasSkippedMfa) {
      updateUserLoginDetails(payload);
    }
    const deviceShareIndex =
      shareIndex || this.context.rootState[TKEY_MODULE_KEY].settingsPageData?.deviceShare?.share?.share.shareIndex.toString("hex");
    if (verifier && verifierId && deviceShareIndex) {
      await this.updateDeviceIndex({ verifier, verifierId, deviceShareIndex });
    }

    if (isLoginCompleted || hasSkippedTkey || hasSkippedMfa) {
      this._reinit();
    }
  }

  @Action
  async addOrUpdateLoginRecord(params: {
    clientId: string;
    dappUrl: string;
    loginRoute: string;
    loginType: LoginType;
    verifierId?: string;
    verifier?: string;
    webauthnEnabled?: boolean;
    errorStack?: string;
    hasSkippedTkey?: boolean;
    isWebauthnLogin?: boolean;
    shareIndex?: string;
    isLoginCompleted?: boolean;
    walletAddress?: string;
    hasSkippedMfa?: boolean;
    mfaLevel?: string;
    mobileOrigin?: string;
    sessionId: string;
  }): Promise<void> {
    const { authToken } = this.context.rootState[USER_MODULE_KEY];
    if (!authToken) {
      return;
    }
    const {
      errorStack,
      hasSkippedTkey,
      loginRoute,
      dappUrl,
      clientId,
      isLoginCompleted,
      webauthnEnabled,
      isWebauthnLogin,
      shareIndex,
      verifierId,
      verifier,
      loginType,
      hasSkippedMfa,
      mfaLevel,
      mobileOrigin,
      sessionId,
    } = params;
    if (this.loginRecordId) {
      this.UpdateLoginRecord({
        shareIndex,
        verifierId,
        verifier,
        errorStack,
        hasSkippedTkey,
        isLoginCompleted,
        loginType,
        hasSkippedMfa,
        mfaLevel,
        mobileOrigin,
      });
    } else {
      this.addLoginRecord({
        clientId,
        verifierId,
        verifier,
        dappUrl,
        loginRoute,
        webauthnEnabled,
        errorStack,
        hasSkippedTkey,
        isWebauthnLogin,
        shareIndex,
        isLoginCompleted,
        loginType,
        mfaLevel,
        mobileOrigin,
        sessionId,
      });
    }
  }

  @Mutation
  public updateState(state: { currentLoginPath?: string; loginRecordId?: string; dappId?: number }) {
    const { currentLoginPath, loginRecordId, dappId } = state;
    if (currentLoginPath) this.currentLoginPath = currentLoginPath;
    if (loginRecordId) this.loginRecordId = loginRecordId;
    if (dappId) this.dappId = dappId;
  }
}

const loginPerfModule = getModule(LoginPerfModule);

installStorePlugin({
  key: LOGIN_PERF_MODULE_KEY,
  saveState: (key: string, state: Record<string, unknown>, storage?: Storage) => {
    storage?.setItem(key, JSON.stringify(state));
  },
  restoreState: (key: string, storage?: Storage) => {
    const value = storage?.getItem(key);
    if (typeof value === "string") {
      // If string, parse, or else, just return
      const parsedValue = JSON.parse(value || "{}");
      return {
        [LOGIN_PERF_MODULE_KEY]: parsedValue[LOGIN_PERF_MODULE_KEY],
      };
    }
    return value || {};
  },
  storage: SESSION_STORAGE_KEY,
});

export default loginPerfModule;
