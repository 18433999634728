/* eslint-disable max-classes-per-file */
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import { subkey } from "@toruslabs/openlogin-subkey";
import { randomId } from "@toruslabs/openlogin-utils";
import { cloneDeep, merge, pickBy } from "lodash-es";
import log from "loglevel";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import config, { openLoginVerifiers } from "@/config";
import { getExternalAuthToken, updateUserInfo } from "@/graphql/mutations";
import { registerUserExtendedMutation } from "@/graphql/operations/__generated__/user";
import vuetify from "@/plugins/vuetify";
import {
  DAPP_MODULES_STORE_KEY,
  DEVICE_MODULE_KEY,
  ERROR_MISSING_PARAMS,
  LOCAL_STORAGE_KEY,
  LOGIN_PERF_MODULE_KEY,
  OPENLOGIN_DAPP_MODULE_KEY,
  OPENLOGIN_DAPP_MODULE_SUFFIX,
  PREFERENCES_OP,
  STORAGE_TYPE,
  TKEY_MODULE_KEY,
  USER_MODULE_KEY,
} from "@/utils/enums";
import {
  LoginConfig,
  LoginConfigItem,
  MFA_LEVELS,
  MfaLevelType,
  SUPPORTED_KEY_CURVES,
  SUPPORTED_KEY_CURVES_TYPE,
  TouchIDPreferences,
  TouchIDPreferencesType,
  WhiteLabelParams,
} from "@/utils/interfaces";
import { hashMessage, signMessage } from "@/utils/signMessage";
import { canPreserveState, getBufferFromHexKey, getJoinedKey, redirectToDapp, setTheme } from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";
import { SkipSync } from "../skipSync";
import deviceModule from "./device";
import loginPerfModule from "./loginPerf";
import userModule from "./user";

export class DappModule extends VuexModule {
  @SkipSync(DAPP_MODULES_STORE_KEY)
  privateKey = "";

  @SkipSync(DAPP_MODULES_STORE_KEY)
  idToken = "";

  // @SkipSync(DAPP_MODULES_STORE_KEY)
  dappShare = "";

  // @SkipSync(DAPP_MODULES_STORE_KEY)
  sessionId = "";

  clientId = "";

  /**
   * WARNING: do not set redirectUrl lightly, this should only be set after validations (eg. middleware)
   */
  redirectUrl = "";

  /**
   * dapp config
   */
  touchIDPreference: TouchIDPreferencesType = TouchIDPreferences.UNSET;

  customLoginConfig: Partial<LoginConfig> = {};

  currentLoginProvider = "";

  mfaLevel: MfaLevelType = MFA_LEVELS.DEFAULT;

  getWalletKey = false;

  appState = "";

  skipTKey = false;

  curve: SUPPORTED_KEY_CURVES_TYPE = SUPPORTED_KEY_CURVES.SECP256K1;

  mobileOrigin = "";

  siteMetadata = {
    icon: "",
    name: "",
    url: "",
    date: new Date(),
  };

  selectedLanguage = "";

  whiteLabel: WhiteLabelParams = {};

  persistedUserdappInfo: Partial<NonNullable<registerUserExtendedMutation["res"]>["userDapp"]> = {};

  sessionTime = 86400; // seconds

  webauthnTransports: AuthenticatorTransport[] = ["internal"];

  _sessionNamespace = "";

  get loginConfig(): LoginConfig {
    const localLoginConfig = cloneDeep(config.loginConfig);
    const finalConfig = merge(localLoginConfig, this.customLoginConfig);
    return finalConfig;
  }

  get canSendDappShare(): boolean {
    const { userInfo } = this.context.rootState[USER_MODULE_KEY];
    const { tKeyPrivKey, keyMode } = this.context.rootState[TKEY_MODULE_KEY];
    const { aggregateVerifier, verifier: userVerifier } = userInfo;
    const customVerifier = aggregateVerifier || userVerifier;
    // TODO: Maybe add client whitelist
    // check if tkey exists (!tkeyPrivKey)
    if (!tKeyPrivKey || keyMode === "1/1") return false;
    return !openLoginVerifiers.includes(customVerifier);
  }

  get isCustomVerifier(): boolean {
    const currentConfig = this.loginConfig[this.currentLoginProvider];
    if (!currentConfig) return false;
    if (openLoginVerifiers.includes(currentConfig.verifier)) {
      return false;
    }
    if (openLoginVerifiers.includes(currentConfig.verifierSubIdentifier)) {
      return false;
    }
    if (currentConfig.walletVerifier && openLoginVerifiers.includes(currentConfig.walletVerifier)) {
      return false;
    }
    return true;
  }

  @Mutation
  public setSessionId() {
    if (!this.sessionId) this.sessionId = randomId();
  }

  @Mutation
  public setUserDappPersistedInfo(info: Partial<NonNullable<registerUserExtendedMutation["res"]>["userDapp"]>) {
    this.persistedUserdappInfo = { ...this.persistedUserdappInfo, ...info };
  }

  @Mutation
  public setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  @Mutation
  public setTouchIDPreference(touchIDPreference: TouchIDPreferencesType): void {
    this.touchIDPreference = touchIDPreference;
  }

  @Mutation
  public setSiteMetadata(params: { icon?: string; name: string; url: string }): void {
    const { icon, name, url } = params;
    this.siteMetadata = {
      icon: icon || this.siteMetadata.icon || "",
      name: name || this.siteMetadata.name,
      url: url || this.siteMetadata.url,
      date: new Date(),
    };
  }

  @Mutation
  public setLoginParams(params: {
    clientId: string;
    currentLoginProvider: string;
    skipTKey?: boolean;
    redirectUrl?: string;
    getWalletKey?: boolean;
    mfaLevel?: MfaLevelType;
    appState?: string;
    dappShare?: string;
    sessionTime?: number;
    sessionId?: string;
    curve?: string;
    mobileOrigin?: string;
    webauthnTransports?: AuthenticatorTransport[];
    _sessionNamespace?: string;
  }): void {
    const {
      clientId,
      skipTKey,
      currentLoginProvider,
      redirectUrl,
      getWalletKey,
      mfaLevel,
      appState,
      dappShare,
      sessionTime,
      sessionId,
      curve,
      mobileOrigin,
      webauthnTransports,
      _sessionNamespace,
    } = params;
    if (clientId) this.clientId = clientId;
    // This will throw if not valid url
    try {
      if (redirectUrl) this.redirectUrl = new URL(redirectUrl).href;
    } catch (error) {
      log.error(error);
      throw new Error(ERROR_MISSING_PARAMS);
    }
    if (currentLoginProvider) this.currentLoginProvider = currentLoginProvider;
    if (getWalletKey !== undefined) this.getWalletKey = getWalletKey;
    if (mfaLevel) this.mfaLevel = mfaLevel;
    if (appState) this.appState = appState;
    if (dappShare) this.dappShare = dappShare;
    if (sessionTime) this.sessionTime = sessionTime;
    this.sessionId = sessionId || "";
    if (curve) this.curve = curve as SUPPORTED_KEY_CURVES_TYPE;
    if (skipTKey !== undefined) this.skipTKey = skipTKey;
    if (mobileOrigin) this.mobileOrigin = mobileOrigin as string;
    if (webauthnTransports && webauthnTransports.length > 0) this.webauthnTransports = webauthnTransports;
    this._sessionNamespace = _sessionNamespace || "";

    log.info(
      {
        clientId: this.clientId,
        currentLoginProvider: this.currentLoginProvider,
        redirectUrl: this.redirectUrl,
        getWalletKey: this.getWalletKey,
        mfaLevel: this.mfaLevel,
        appState: this.appState,
        dappShare: this.dappShare,
        sessionTime: this.sessionTime,
        curve: this.curve,
        skipTKey: this.skipTKey,
        mobileOrigin: this.mobileOrigin,
        webauthnTransports: this.webauthnTransports,
        _sessionNamespace: this._sessionNamespace,
      },
      "current params"
    );
  }

  @Mutation
  public modifyCustomLoginConfig(params: { cfg: LoginConfigItem; loginProvider: string }): void {
    const { cfg, loginProvider } = params;
    const localConfig = { ...this.customLoginConfig };
    const currCfg = localConfig[loginProvider];

    if (!currCfg) {
      if (!cfg.verifierSubIdentifier) {
        // prevent verifierSubIdentifier from being overridden later in get loginConfig function.
        localConfig[loginProvider] = { ...cfg, verifierSubIdentifier: "", loginProvider };
      } else {
        localConfig[loginProvider] = { ...cfg, loginProvider };
      }
    } else if (
      Object.prototype.hasOwnProperty.call(cfg, "verifier") &&
      cfg.verifier !== currCfg.verifier &&
      !Object.prototype.hasOwnProperty.call(cfg, "verifierSubIdentifier") &&
      Object.prototype.hasOwnProperty.call(localConfig[loginProvider], "verifierSubIdentifier")
    ) {
      // a custom verifier might be sent without verifierSubIdentifier key
      // in custom config, in that case we should prevent it from getting overriden
      // by default loginProvider verifierSubIdentifier.
      localConfig[loginProvider] = { ...merge(currCfg, cfg), verifierSubIdentifier: "", loginProvider };
    } else {
      localConfig[loginProvider] = { ...merge(currCfg, cfg), loginProvider };
    }

    const finalConfigItem = localConfig[loginProvider];

    if (finalConfigItem && !finalConfigItem.walletVerifier) finalConfigItem.walletVerifier = finalConfigItem.verifier;

    this.customLoginConfig = localConfig;
  }

  @Mutation
  public setPrivateKey(key: string): void {
    this.privateKey = key;
  }

  @Mutation
  updateState(state: Partial<DappModule>): void {
    const {
      touchIDPreference,
      customLoginConfig,
      privateKey,
      clientId,
      redirectUrl,
      currentLoginProvider,
      whiteLabel,
      idToken,
      sessionId,
      _sessionNamespace,
      sessionTime,
      dappShare,
    } = state;
    if (touchIDPreference !== undefined) this.touchIDPreference = touchIDPreference;
    if (customLoginConfig !== undefined) this.customLoginConfig = customLoginConfig;
    if (privateKey !== undefined) this.privateKey = privateKey;
    if (clientId !== undefined) this.clientId = clientId;
    if (redirectUrl !== undefined) this.redirectUrl = redirectUrl;
    if (currentLoginProvider !== undefined) this.currentLoginProvider = currentLoginProvider;
    if (whiteLabel !== undefined) this.whiteLabel = whiteLabel;
    if (idToken !== undefined) this.idToken = idToken;
    if (sessionId !== undefined) this.sessionId = sessionId;
    if (_sessionNamespace !== undefined) this._sessionNamespace = _sessionNamespace;
    if (sessionTime !== undefined) this.sessionTime = sessionTime;
    if (dappShare !== undefined) this.dappShare = dappShare;
  }

  @Mutation
  public setLanguage(value: string): void {
    this.selectedLanguage = value;
  }

  @Action
  public setWhiteLabel(whiteLabel: WhiteLabelParams): void {
    const { currentDappClientId } = this.context.rootState[USER_MODULE_KEY];
    log.info(this.clientId, currentDappClientId, JSON.stringify(whiteLabel), "setting whitelabel");
    if (this.clientId === currentDappClientId) {
      setTheme(whiteLabel, vuetify.framework);
    }
    this.context.commit(`updateState`, { whiteLabel });
  }

  @Action
  async setPreferencesAndRedirect(params: {
    pid: string;
    redirectUrl: string;
    popupWindow: boolean;
    result: {
      tKey: string;
      oAuthPrivateKey: string;
      walletKey: string;
    };
    verifier: string;
    verifierId: string;
    disableAlwaysSkip?: boolean;
    alwaysSkip?: boolean;
  }): Promise<void> {
    const operationName = PREFERENCES_OP;
    window.performance.mark(`${operationName}_start`);

    const { redirectUrl, result, verifier, verifierId, popupWindow, pid, alwaysSkip, disableAlwaysSkip } = params;

    this.context.commit(
      `${DEVICE_MODULE_KEY}/setLastLoggedIn`,
      {
        verifier,
        verifierId,
      },
      { root: true }
    );
    this.context.commit(`${DEVICE_MODULE_KEY}/setLastLoggedInVerifier`, verifier, { root: true });
    const { userInfo, walletKeyInfo } = this.context.rootState[USER_MODULE_KEY];
    // get device share from here and return dapp share depending on login methods
    const { keyMode } = this.context.rootState[TKEY_MODULE_KEY];
    const {
      email,
      aggregateVerifier,
      name,
      profileImage,
      typeOfLogin,
      verifier: userVerifier,
      verifierId: userVerifierId,
      idToken: oAuthIdToken,
      accessToken: oAuthAccessToken,
    } = userInfo;
    let localDappShare = "";
    if (this.canSendDappShare) {
      // we can send dapp share in this case
      const exportedShare = await this.context.dispatch(`${TKEY_MODULE_KEY}/exportDeviceShare`, {}, { root: true });
      localDappShare = exportedShare;
    }

    const finalResult: Record<string, unknown> = {
      oAuthPrivateKey: result.oAuthPrivateKey as string,
      store: {
        touchIDPreference: this.touchIDPreference,
        appState: this.appState,
        email,
        aggregateVerifier,
        name,
        profileImage,
        typeOfLogin,
        verifier: userVerifier,
        verifierId: userVerifierId,
        dappShare: localDappShare || this.dappShare,
        oAuthIdToken: this.isCustomVerifier ? oAuthIdToken : "", // only send original id token for custom verifiers
        oAuthAccessToken: this.isCustomVerifier ? oAuthAccessToken : "",
      },
    };

    let app_pub_key = "";
    let app_signature = "";

    if (result.tKey) {
      const scopedKey = subkey(result.tKey as string, Buffer.from(this.clientId, "base64"));
      this.context.commit(
        "updateState",
        {
          privateKey: scopedKey,
        },
        { root: false }
      );
      finalResult.privKey = scopedKey;
      finalResult.tKey = result.tKey;

      if (this.isCustomVerifier && keyMode !== "v1") {
        finalResult.coreKitKey = result.tKey;
        finalResult.coreKitEd25519PrivKey = getED25519Key(result.tKey).sk.toString("hex");
      }

      if (this.curve === SUPPORTED_KEY_CURVES.ED25519) {
        const ed25519Key = getED25519Key(getBufferFromHexKey(scopedKey));
        app_pub_key = ed25519Key.pk.toString("hex");
        app_signature = signMessage(ed25519Key.sk.toString("hex"), userModule.challenge, SUPPORTED_KEY_CURVES.ED25519);
      } else {
        app_pub_key = getPublicCompressed(getBufferFromHexKey(scopedKey)).toString("hex");
        app_signature = signMessage(scopedKey, hashMessage(userModule.challenge).toString("hex"), SUPPORTED_KEY_CURVES.SECP256K1);
      }

      const updateUserDappParams = {
        dapp_id: loginPerfModule.dappId,
        dapp_public_key: app_pub_key,
        device_id: deviceModule.verifierIDDeviceIDMap[getJoinedKey(aggregateVerifier || verifier, verifierId)] || "",
      };
      await this.context.dispatch("updateUserDappInfo", { payload: updateUserDappParams }, { root: false });
    }

    if (this.getWalletKey) {
      log.info("wallet key is being sent");
      const { persistedUserInfo } = userModule;
      // for v2 users who have enabled dual account mode via support settings
      if (persistedUserInfo?.v2_wallet_key_enabled && keyMode !== "v1") {
        finalResult.walletKey = walletKeyInfo.privateKey ? walletKeyInfo.privateKey.padStart(64, "0") : "";
      } else {
        finalResult.walletKey = result.walletKey || "";
      }
    }
    // resetting it back to `dapp_public_key` false as tkey is generated and disableAlwaysSkip is sent as true
    if (disableAlwaysSkip && finalResult.tKey) {
      await userModule.updateUserPersistedInfo({ payload: { always_skip_tkey: false } });
    } else if (alwaysSkip) {
      log.info("always skipping", alwaysSkip);
      await userModule.updateUserPersistedInfo({ payload: { always_skip_tkey: true } });
    }

    if (app_pub_key && this.clientId !== OPENLOGIN_DAPP_MODULE_KEY) {
      try {
        const oauth_pub_key = getPublicCompressed(getBufferFromHexKey(finalResult.oAuthPrivateKey as string)).toString("hex");
        if (!this.sessionId) {
          throw new Error("SessionId is missing while fetching external token");
        }

        const sessionNonce = getPublicCompressed(getBufferFromHexKey(this.sessionId as string)).toString("hex");
        log.debug("session nonce", sessionNonce, this.redirectUrl);
        const token = await getExternalAuthToken({
          client_id: this.clientId,
          timeout: this.sessionTime,
          app_public_key: app_pub_key,
          curve: this.curve,
          email: userInfo.email,
          name: userInfo.name,
          verifier: userInfo.verifier,
          aggregate_verifier: userInfo.aggregateVerifier,
          verifier_id: userInfo.verifierId,
          profile_image: userInfo.profileImage,
          session_nonce: sessionNonce,
          oauth_public_key: oauth_pub_key,
          app_signed_message: app_signature,
        });
        if (!token) {
          log.error("empty token found while fetching external auth token");
        } else {
          this.context.commit(
            "updateState",
            {
              idToken: token,
            },
            { root: false }
          );
          (finalResult.store as Record<string, string>).idToken = token;
        }
      } catch (error) {
        log.error("error while fetching external auth token", error);
      }
    }

    loginPerfModule.markRouteAndTime({
      route: "end",
      isEnd: true,
      operation: operationName,
    });
    // reinit loginPerf before redirecting
    this.context.commit(`${LOGIN_PERF_MODULE_KEY}/_reinit`, {}, { root: true });
    this.context.commit(`${LOGIN_PERF_MODULE_KEY}/resetDappId`, {}, { root: true });

    await redirectToDapp(
      {
        redirectUrl,
        popupWindow,
        sessionTime: this.sessionTime,
        sessionId: this.sessionId,
        _sessionNamespace: this._sessionNamespace,
      },
      { result: finalResult, pid }
    );
  }

  @Action
  async logout(): Promise<void> {
    this.context.commit(`${USER_MODULE_KEY}/resetCurrentClientId`, { clientId: this.clientId }, { root: true });
    this.context.commit(`${USER_MODULE_KEY}/logout`, {}, { root: true });
    this.context.commit(`${TKEY_MODULE_KEY}/logout`, {}, { root: true });
    this.context.commit(
      "updateState",
      {
        touchIDPreference: TouchIDPreferences.DISABLED,
        customLoginConfig: {},
        privateKey: "",
        clientId: "",
        redirectUrl: this.redirectUrl, // do not clear this, or logout redirects wont work
        currentLoginProvider: "",
        whiteLabel: {},
        sessionId: "",
        sessionTime: 86400,
        dappShare: "",
        idToken: "",
        webauthnTransports: ["internal"],
        _sessionNamespace: "",
      },
      { root: false }
    );
    // this.context.commit(`${DEVICE_MODULE_KEY}/setLastLoggedInVerifier`, "", { root: true });
  }

  @Action
  async updateUserDappInfo(params: { payload: { dapp_public_key: string; dapp_id: number; device_id?: string }; throwError?: boolean }) {
    const { payload, throwError } = params;
    const { dapp_public_key, dapp_id } = payload;
    const newValues = pickBy({ dapp_public_key, dapp_id }, (val: unknown, key: string) => {
      const existingInfo = (this.persistedUserdappInfo as Record<string, unknown>) || {};
      if (existingInfo[key] === val) {
        return false;
      }
      return true;
    });
    if (newValues && Object.keys(newValues).length > 0) {
      try {
        await updateUserInfo(payload);
        this.setUserDappPersistedInfo({ ...payload });
      } catch (error) {
        if (throwError) throw error;
      }
    }
  }
}

export function createDappModuleInternal(clientId: string, storageKey: STORAGE_TYPE): DappModule {
  const finalStorageKey: STORAGE_TYPE = storageKey;

  const dappStorageKey = `${clientId}${OPENLOGIN_DAPP_MODULE_SUFFIX}`;
  @Module({
    namespaced: true,
    name: clientId,
    store,
    dynamic: true,
    stateFactory: true,
    preserveState: canPreserveState(dappStorageKey, finalStorageKey),
  })
  class DappModuleInternal extends DappModule {}
  return getModule(DappModuleInternal);
}

@Module({
  namespaced: true,
  name: DAPP_MODULES_STORE_KEY,
  store,
  dynamic: true,
  preserveState: false,
})
export class DappModulesStore extends VuexModule {
  dappModules: Record<string, DappModule> = {};

  @Mutation
  addDappModule(params: { storageType: STORAGE_TYPE; key: string }): void {
    const { key, storageType } = params;
    if (this.dappModules[key]) return;
    const dm = createDappModuleInternal(key, storageType);
    this.dappModules = { ...this.dappModules, [key]: dm };
  }

  @Mutation
  async deleteDappModule(clientId: string): Promise<void> {
    await this.dappModules[clientId].logout();
  }
}

export const dappModulesStoreModule = getModule(DappModulesStore);

export function registerDappModule(clientId: string, storageKey: STORAGE_TYPE = LOCAL_STORAGE_KEY): void {
  log.info("registering module", clientId);
  if (!clientId) return;

  if (dappModulesStoreModule.dappModules[clientId]) return;
  const finalStorageKey: STORAGE_TYPE = storageKey;

  const dappStorageKey = `${clientId}${OPENLOGIN_DAPP_MODULE_SUFFIX}`;

  dappModulesStoreModule.addDappModule({ storageType: storageKey, key: clientId });
  installStorePlugin({ key: dappStorageKey, moduleKey: clientId, storage: finalStorageKey });
}
