import { ApolloClient, ApolloLink, concat, createHttpLink, from, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/vue";
import log from "loglevel";

import config from "./config";

let authToken = "";

export function setAuthToken(token: string) {
  authToken = token;
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = authToken;

  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ message }) => {
      // if (extensions?.code !== "UNAUTHENTICATED") {
      log.error(
        `[GraphQL error]: Message: ${message}
        )}`
      );
      // }
      // if (message.includes("Invalid header") && window.location.pathname !== "/") {
      //   tKeyModule.stopShareTransferRequestListener();
      //   userModule.setAuthToken("");
      //   registerDappModule(userModule.currentDappClientId);
      //   log.info("logging out", userModule.currentDappClientId);
      //   await dappModulesStoreModule.deleteDappModule(userModule.currentDappClientId);
      //   await router.push({ path: "/", hash: `#forcedErrorMessage=Session expired` });
      // }
    });
  if (networkError) log.error(`[Network error]`, networkError);
});

const requestTimeLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  const tx = Sentry.startTransaction({ name: `${operation.operationName}_openlogin` });
  operation.setContext({ tx });

  return forward(operation).map((data) => {
    tx.finish();
    return data;
  });
});

const httpLink = createHttpLink({
  uri: config.openloginBackend,
  credentials: "include",
  fetch,
});

// Cache implementation
// currently we are not using any real time data query so default cache conf is fine.
// if any real time data query is added in future, make sure to amend cache policy to get real time data.
const cache = new InMemoryCache();

const client = new ApolloClient({
  link: from([errorLink, requestTimeLink, concat(authMiddleware, httpLink)]),
  cache,
});

export { client };
