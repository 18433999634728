import { privateToAddress, toChecksumAddress } from "@ethereumjs/util";
import { getPublic, sign } from "@toruslabs/eccrypto";
import { get, post } from "@toruslabs/http-helpers";
import { encryptData, keccak256 } from "@toruslabs/metadata-helpers";
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import { safebtoa } from "@toruslabs/openlogin-utils";
import { Mutex } from "async-mutex";
import bowser, { OS_MAP, PLATFORMS_MAP } from "bowser";
import { ec as EC } from "elliptic";
import log from "loglevel";
import type { VuetifyPreset } from "vuetify";
import type { VuetifyThemeVariant } from "vuetify/types/services/theme";

import { languageMap, loadLanguageAsync } from "@/plugins/i18n-setup";

import config from "../config";
import { LOCAL_STORAGE_KEY, STORAGE_TYPE } from "./enums";
import { WhiteLabelParams } from "./interfaces";
// import { TKeyAccount } from "./interfaces";

export function getBufferFromHexKey(hexString: string): Buffer {
  return Buffer.from(hexString.padStart(64, "0"), "hex");
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const prettyPrintData = (data: Record<string, unknown>): string => {
  let finalString = "";
  Object.keys(data).forEach((x) => {
    finalString = `${finalString}\n${x}: ${data[x]}`;
  });
  return finalString;
};

export function constructUrlParams(params: Record<string, string>): string {
  const localUrl = new URL("https://example.com");
  Object.keys(params).forEach((x) => {
    if (params[x]) localUrl.searchParams.append(x, params[x]);
  });
  return localUrl.searchParams.toString();
}

export function validateInternalHost(urlString: string): boolean {
  try {
    const allowedHostnameEndings = ["localhost", "tor.us", "openlogin.com"];
    const url = new URL(urlString);
    let allowed = false;
    allowedHostnameEndings.forEach((hostnameEnding) => {
      if (url.hostname.endsWith(hostnameEnding)) allowed = true;
    });
    return allowed;
  } catch (error) {
    log.error(error, "could not validate url");
    return false;
  }
}

export function validateInternalHostExceptLocalhost(urlString: string): boolean {
  try {
    const allowedHostnameEndings = ["tor.us", "openlogin.com"];
    const url = new URL(urlString);
    let allowed = false;
    allowedHostnameEndings.forEach((hostnameEnding) => {
      if (url.hostname.endsWith(hostnameEnding)) allowed = true;
    });
    return allowed;
  } catch (error) {
    log.error(error, "could not validate url");
    return false;
  }
}

export async function redirectToDapp(
  {
    redirectUrl,
    popupWindow,
    sessionTime,
    sessionId,
    _sessionNamespace,
  }: { redirectUrl: string; popupWindow: boolean; sessionTime: number; sessionId: string; _sessionNamespace: string },
  { result, pid }: { result: Record<string, unknown>; pid: string }
): Promise<void> {
  const isInternalHost = validateInternalHost(redirectUrl);
  const finalResult = { ...result };
  if (!isInternalHost) {
    delete finalResult.oAuthPrivateKey;
    delete finalResult.tKey;
    delete finalResult.walletKey;
  }

  if (finalResult.privKey) {
    finalResult.ed25519PrivKey = getED25519Key(result.privKey as string).sk.toString("hex");
  }

  let finalUrl: URL;

  if (sessionId) {
    const privKey = getBufferFromHexKey(sessionId);
    const publicKeyHex = getPublic(privKey).toString("hex");
    const encData = await encryptData(sessionId.padStart(64, "0"), finalResult);
    const signature = (await sign(privKey, keccak256(encData))).toString("hex");
    await post(`${config.storageServerUrl}/store/set`, {
      key: publicKeyHex,
      data: encData,
      signature,
      timeout: sessionTime,
      namespace: _sessionNamespace,
    });
  }

  if (popupWindow) {
    finalUrl = new URL(`${window.location.origin}/popup-window`);
    const hash = safebtoa(JSON.stringify({ ...finalResult, _nextRedirect: redirectUrl }));
    finalUrl.hash = `result=${hash}&_pid=${pid}`;
    if (sessionId) {
      finalUrl.hash = `${finalUrl.hash}&sessionId=${sessionId}`;
    }

    if (_sessionNamespace) {
      finalUrl.hash = `${finalUrl.hash}&sessionNamespace=${_sessionNamespace}`;
    }

    // allow async ops to finish
    setTimeout(() => {
      window.location.replace(finalUrl.href);
    }, 200);
  } else {
    finalUrl = new URL(redirectUrl);
    const hash = safebtoa(JSON.stringify(finalResult));
    finalUrl.hash = `result=${hash}&_pid=${pid}`;
    if (sessionId) {
      finalUrl.hash = `${finalUrl.hash}&sessionId=${sessionId}`;
    }

    if (_sessionNamespace) {
      finalUrl.hash = `${finalUrl.hash}&sessionNamespace=${_sessionNamespace}`;
    }

    setTimeout(() => {
      window.location.href = finalUrl.href;
    }, 200);
  }
}

export function canPreserveState(key: string, storage: STORAGE_TYPE): boolean {
  if (!config.isStorageAvailable[storage]) return false;
  // return getStorage(storage)?.getItem(key) !== null;
  return false;
}

export function getStorage(key: STORAGE_TYPE): Storage | undefined {
  if (config.isStorageAvailable[key]) return window[key];
  return undefined;
}

export function getJoinedKey(verifier: string, verifierId: string): string {
  return `${verifier}\x1c${verifierId}`;
}

export function getAppLogo(isDark = false): string {
  if (process.env.VUE_APP_TORUS_BUILD_ENV !== "production") {
    return isDark ? "web3auth-wordmark-light.svg" : "web3auth-wordmark.svg";
  }
  return isDark ? "web3auth-wordmark-light.svg" : "web3auth-wordmark.svg";
}

export function getUserLanguage(): string {
  const language = getStorage(LOCAL_STORAGE_KEY)?.getItem("torus-locale");
  if (language) return language;

  const { navigator } = window;
  let userLanguage = (navigator as unknown as { userLanguage: string }).userLanguage || navigator.language || "en-US";
  const userLanguageArr = userLanguage.split("-");
  userLanguage = Object.prototype.hasOwnProperty.call(languageMap, userLanguageArr[0]) ? userLanguageArr[0] : "en";
  return userLanguage;
}

export const openLoginLogo =
  process.env.VUE_APP_TORUS_BUILD_ENV !== "production" && process.env.VUE_APP_TORUS_BUILD_ENV !== "staging"
    ? "torus-open-login-logo-beta.svg"
    : "torus-open-login-logo.svg";

export function formatDate(date: string | Date): string {
  if (!date) return "";
  const toFormat = date instanceof Date ? date : new Date(date);
  const day = toFormat.getDate().toString().padStart(2, "0");
  const month = (toFormat.getMonth() + 1).toString().padStart(2, "0");
  const year = toFormat.getFullYear().toString().substring(2);
  return `${day}/${month}/${year}, ${toFormat.toLocaleString(undefined, { timeStyle: "short", hour12: false })}`;
}

export function getBrowserIcon(name: string): string {
  const BROWSER_ALIASES: Record<string, string> = {
    Chrome: "chrome",
    Chromium: "chrome",
    Firefox: "firefox",
    Safari: "safari",
    Brave: "brave",
    "Samsung Internet for Android": "android",
    "Microsoft Edge": "edge",
  };

  return BROWSER_ALIASES[name] ? `browser_${BROWSER_ALIASES[name]}` : "browser";
}

export function getCustomDeviceInfo(): Record<string, string> | undefined {
  if ((navigator as unknown as { brave: boolean })?.brave) {
    return {
      browser: "Brave",
    };
  }
  return undefined;
}

export function lightenColor(hex: string, a = 1): string {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const r = Math.floor(Number(`0x${c[0]}${c[1]}`) * a + 0xff * (1 - a));
    const g = Math.floor(Number(`0x${c[2]}${c[3]}`) * a + 0xff * (1 - a));
    const b = Math.floor(Number(`0x${c[4]}${c[5]}`) * a + 0xff * (1 - a));

    // eslint-disable-next-line no-bitwise
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`;
  }
  return hex;
}

export function setTheme(whiteLabel: WhiteLabelParams, vuetify: VuetifyPreset): void {
  log.info("modal: setTheme", JSON.stringify(whiteLabel || {}));
  vuetify.theme.dark = whiteLabel?.dark || false;
  if (whiteLabel?.theme) {
    if (whiteLabel.theme.primary) {
      whiteLabel.theme.primary2 = lightenColor(whiteLabel.theme.primary as string, 0.6);
    }
    if (vuetify.theme.dark) {
      vuetify.theme.themes.dark = { ...vuetify.theme.themes.dark, ...(whiteLabel.theme as VuetifyThemeVariant) };
    } else {
      vuetify.theme.themes.light = { ...vuetify.theme.themes.light, ...(whiteLabel.theme as VuetifyThemeVariant) };
    }
  }
  if (whiteLabel?.defaultLanguage) loadLanguageAsync(whiteLabel.defaultLanguage);
}

/**
 * it will set the end mark for an operation and calculates the time between start and end of any operation
 * Note: make sure to set start marker for operation name (For ex: window.performance.mark(`${operationName}_start`);
 *
 * @param operationName - Name of the operation
 * @returns the time between start and end operation mark
 * before calling this function.
 */
export const measurePerformance = (operationName: string): number => {
  try {
    const measureName = `${operationName}_measure`;
    const startMarkName = `${operationName}_start`;
    const endMarkName = `${operationName}_end`;
    window.performance.mark(endMarkName);
    window.performance.measure(measureName, startMarkName, endMarkName);
    const perfEntry = window.performance.getEntriesByName(measureName);
    window.performance.clearMarks(startMarkName);
    window.performance.clearMarks(endMarkName);
    window.performance.clearMeasures(measureName);
    log.debug("time taken", perfEntry[0].duration, operationName);
    return perfEntry[0].duration;
  } catch (error) {
    // error might occur if start marker is not set before calling
    // this function
    log.error("Error in measurePerformance function", error);
    return 0;
  }
};

export const measurePerformanceAndRestart = (operationName: string): number => {
  try {
    const duration = measurePerformance(operationName);
    const startMarkName = `${operationName}_start`;
    window.performance.mark(startMarkName);
    log.debug("time taken and restarted", duration, operationName);
    return duration;
  } catch (error) {
    log.error("Error in measurePerformance function", error);
    return 0;
  }
};

export const sanitizeUrl = (url: string): URL => {
  try {
    return new URL(url);
  } catch (error) {
    return new URL("https://example.com");
  }
};

export function getPublicFromPrivateKey(privateKeyHex: string): { X: string; Y: string; address: string } {
  const ec = new EC("secp256k1");
  const keyPair = ec.keyFromPrivate(privateKeyHex, "hex");
  const publicKey = keyPair.getPublic();
  const X = publicKey.getX().toString(16);
  const Y = publicKey.getY().toString(16);
  const ethAddressLower = privateToAddress(getBufferFromHexKey(privateKeyHex)).toString("hex");
  return { X, Y, address: toChecksumAddress(`0x${ethAddressLower}`) };
}

export function checkIfTrueValue(val: unknown): boolean {
  if (val === "0") return false;
  if (!val) return false;
  return true;
}

export function getOSName(): string {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getOSName();
}

export function shouldSupportWebAuthn(): boolean {
  const browser = bowser.getParser(window.navigator.userAgent);
  const osName = browser.getOSName();
  const platform = browser.getPlatform();
  return osName !== OS_MAP.MacOS && platform.type === PLATFORMS_MAP.desktop;
}

const webAuthnMutex = new Mutex();

async function checkWebAuthnStatus(transports: AuthenticatorTransport[] = ["internal"]) {
  if (window.self !== window.top) return false;
  if (!window.PublicKeyCredential) return false;
  // we will disable the browser check if internal is not
  // part of the transports array.
  if (transports.includes("internal") && !shouldSupportWebAuthn()) return false;
  let available = true;
  // If we have multiple webauthn transports
  // then we don't need to check that user has a touchID or faceID setup.
  if (transports.length === 0 || (transports.length === 1 && transports[0] === "internal")) {
    available = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  }

  return available;
}

export async function isWebAuthnAvailable(transports: AuthenticatorTransport[] = ["internal"]): Promise<boolean> {
  let result: boolean | undefined;
  const release = await webAuthnMutex.acquire();
  try {
    if (result !== undefined) return result;
    result = await checkWebAuthnStatus(transports);
  } catch (error) {
    log.error("WebAuthn check failed");
  } finally {
    release();
  }
  return result || false;
}

export const getAuthenticatorAttachment = (transports: AuthenticatorTransport[]): AuthenticatorAttachment | undefined => {
  // if only internal option is available, return "platform". This will only show user device options.
  if (!transports || transports.length === 0 || (transports.length === 1 && transports.includes("internal"))) return "platform";
  // if internal option is not available, return "cross-platform".
  // This will only show options which that will be different than the user platform like usb, ble.
  if (!transports.includes("internal")) return "cross-platform";
  // If internal and any cross-platform options are available, return undefined.
  // This will show both user device options and cross-platform options.
  return undefined;
};

export const getUserCountry = async (): Promise<string> => {
  try {
    const result = await get<{ data: { country: string } }>(`${config.passwordlessBackend}/api/v2/user/location`);
    if (result && result.data.country) return result.data.country;
    return "";
  } catch (error) {
    log.error("error getting user country", error);
    return "";
  }
};

export const validatePhoneNumber = async (phoneNumber: string): Promise<boolean> => {
  try {
    const result = await post<{ success: boolean }>(`${config.passwordlessBackend}/api/v2/phone_number/validate`, { phone_number: phoneNumber });
    if (result && result.success) return true;
    return false;
  } catch (error: any) {
    log.error("error validating phone number", error);
    if (error.status === 400) {
      return false;
    }
    // sending true because we don't want the user to be stuck on a flow
    // if there is an error with the api or something went wrong.
    return true;
  }
};
