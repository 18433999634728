import { TORUS_NETWORK, TORUS_NETWORK_TYPE } from "@toruslabs/constants";
import { LogLevelDesc } from "loglevel";

import getCurrentLogLevel from "./logutils";
import {
  APPLE,
  APPLE_LOGIN_PROVIDER,
  DISCORD,
  DISCORD_LOGIN_PROVIDER,
  EMAIL_PASSWORDLESS_LOGIN_PROVIDER,
  FACEBOOK,
  FACEBOOK_LOGIN_PROVIDER,
  GITHUB,
  GITHUB_LOGIN_PROVIDER,
  GOOGLE,
  GOOGLE_LOGIN_PROVIDER,
  JWT,
  KAKAO_LOGIN_PROVIDER,
  LINE,
  LINE_LOGIN_PROVIDER,
  LINKEDIN,
  LINKEDIN_LOGIN_PROVIDER,
  LOCAL_STORAGE_KEY,
  REDDIT,
  REDDIT_LOGIN_PROVIDER,
  SESSION_STORAGE_KEY,
  SMS_PASSWORDLESS_LOGIN_PROVIDER,
  TWITCH,
  TWITCH_LOGIN_PROVIDER,
  TWITTER,
  TWITTER_LOGIN_PROVIDER,
  WEBAUTHN,
  WEBAUTHN_LOGIN_PROVIDER,
  WECHAT_LOGIN_PROVIDER,
} from "./utils/enums";
import type { LoginConfig, LoginConfigItem } from "./utils/interfaces";
import storageAvailable from "./utils/storageAvailable";

const {
  VUE_APP_GOOGLE_CLIENT_ID,
  VUE_APP_APPLE_CLIENT_ID,
  VUE_APP_DISCORD_CLIENT_ID,
  VUE_APP_FACEBOOK_CLIENT_ID,
  VUE_APP_GITHUB_CLIENT_ID,
  VUE_APP_LINE_CLIENT_ID,
  VUE_APP_LINKEDIN_CLIENT_ID,
  VUE_APP_REDDIT_CLIENT_ID,
  VUE_APP_TWITCH_CLIENT_ID,
  VUE_APP_TWITTER_CLIENT_ID,
  VUE_APP_HOSTED_EMAIL_PASSWORDLESS_CLIENT_ID,
  VUE_APP_WECHAT_CLIENT_ID,
  VUE_APP_KAKAO_CLIENT_ID,
  VUE_APP_PROXY_NETWORK,
  VUE_APP_WEBAUTHN_CLIENT_ID,
  VUE_APP_VERIFIER_SUBIDENTIFIER,
  VUE_APP_LOGIN_DOMAIN,
  // tkey verifiers
  VUE_APP_GOOGLE_VERIFIER,
  VUE_APP_FACEBOOK_VERIFIER,
  VUE_APP_REDDIT_VERIFIER,
  VUE_APP_DISCORD_VERIFIER,
  VUE_APP_TWITCH_VERIFIER,
  VUE_APP_GITHUB_VERIFIER,
  VUE_APP_KAKAO_VERIFIER,
  VUE_APP_LINKEDIN_VERIFIER,
  VUE_APP_TWITTER_VERIFIER,
  // VUE_APP_WEIBO_VERIFIER,
  VUE_APP_WECHAT_VERIFIER,
  VUE_APP_LINE_VERIFIER,
  VUE_APP_APPLE_VERIFIER,
  VUE_APP_HOSTED_EMAIL_PASSWORDLESS_VERIFIER,
  VUE_APP_HOSTED_SMS_PASSWORDLESS_VERIFIER,
  VUE_APP_WEBAUTHN_VERIFIER,
  // wallet verifiers
  VUE_APP_WALLET_GOOGLE_VERIFIER,
  VUE_APP_WALLET_FACEBOOK_VERIFIER,
  VUE_APP_WALLET_REDDIT_VERIFIER,
  VUE_APP_WALLET_DISCORD_VERIFIER,
  VUE_APP_WALLET_TWITCH_VERIFIER,
  VUE_APP_WALLET_GITHUB_VERIFIER,
  VUE_APP_WALLET_KAKAO_VERIFIER,
  VUE_APP_WALLET_LINKEDIN_VERIFIER,
  VUE_APP_WALLET_TWITTER_VERIFIER,
  // VUE_APP_WALLET_WEIBO_VERIFIER,
  VUE_APP_WALLET_WECHAT_VERIFIER,
  VUE_APP_WALLET_LINE_VERIFIER,
  VUE_APP_WALLET_APPLE_VERIFIER,
  VUE_APP_WALLET_HOSTED_EMAIL_PASSWORDLESS_VERIFIER,
  VUE_APP_WALLET_HOSTED_SMS_PASSWORDLESS_VERIFIER,
  VUE_APP_PASSWORDLESS_DOMAIN,
  VUE_APP_OPENLOGIN_BACKEND,
  VUE_APP_METADATA_HOST,
  VUE_APP_DEVELOPER_DASHBOARD_HOST,
  VUE_APP_PASSWORDLESS_BACKEND,
  // BASE_URL,
  VUE_APP_TORUS_BUILD_ENV,
  VUE_APP_TORUS_BUILD_VERSION,
} = process.env;

const logLevel: LogLevelDesc = getCurrentLogLevel();

const config = {
  version: (VUE_APP_TORUS_BUILD_VERSION as string).split(".")[0],
  buildEnv: VUE_APP_TORUS_BUILD_ENV as string,
  developerDashboardHost: VUE_APP_DEVELOPER_DASHBOARD_HOST ?? "https://api-dashboard.web3auth.io",
  openloginBackend: VUE_APP_OPENLOGIN_BACKEND ?? "https://api.openlogin.com/graphql",
  metadataHost: VUE_APP_METADATA_HOST ?? "https://metadata.tor.us",
  apiHost: "https://api.tor.us",
  passwordlessBackend: VUE_APP_PASSWORDLESS_BACKEND ?? "https://admin.openlogin.com",
  storageServerUrl: "https://broadcast-server.tor.us",
  tkeyEmail: {
    host: "https://email.tkey.io/send_mail",
    logo: "https://images.web3auth.io/web3auth-logo-passwordless-email.png",
    name: "Web3Auth",
  },
  torusNetwork: VUE_APP_PROXY_NETWORK as TORUS_NETWORK_TYPE,
  logLevel,
  isLRC: VUE_APP_PROXY_NETWORK === TORUS_NETWORK.TESTNET,
  alwaysAllowedHosts: ["localhost", "127.0.0.1", "sdk.openlogin.com"],
  supportVersionedEnvironments: ["production", "aqua", "cyan", "staging"],
  // redirectPath: `${BASE_URL}auth`.slice(1),
  redirectPath: `/auth`.slice(1),

  isStorageAvailable: {
    [LOCAL_STORAGE_KEY]: storageAvailable(LOCAL_STORAGE_KEY),
    [SESSION_STORAGE_KEY]: storageAvailable(SESSION_STORAGE_KEY),
  },

  // key is the login provider
  loginConfig: {
    [GOOGLE_LOGIN_PROVIDER]: {
      loginProvider: GOOGLE_LOGIN_PROVIDER,
      verifier: VUE_APP_GOOGLE_VERIFIER,
      typeOfLogin: GOOGLE,
      name: GOOGLE,
      description: "login.verifier-google-desc",
      clientId: VUE_APP_GOOGLE_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      showOnModal: true,
      mainOption: true,
      showOnDesktop: true,
      showOnMobile: true,
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_GOOGLE_VERIFIER,
    } as LoginConfigItem,
    [FACEBOOK_LOGIN_PROVIDER]: {
      loginProvider: FACEBOOK_LOGIN_PROVIDER,
      verifier: VUE_APP_FACEBOOK_VERIFIER,
      description: "",
      typeOfLogin: FACEBOOK,
      name: FACEBOOK,
      clientId: VUE_APP_FACEBOOK_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      showOnModal: true,
      mainOption: true,
      showOnDesktop: true,
      showOnMobile: true,
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_FACEBOOK_VERIFIER,
    } as LoginConfigItem,
    [TWITTER_LOGIN_PROVIDER]: {
      loginProvider: TWITTER_LOGIN_PROVIDER,
      verifier: VUE_APP_TWITTER_VERIFIER,
      description: "",
      typeOfLogin: TWITTER,
      name: TWITTER,
      clientId: VUE_APP_TWITTER_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      showOnModal: true,
      mainOption: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "twitter",
        isVerifierIdCaseSensitive: false,
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_TWITTER_VERIFIER,
    } as LoginConfigItem,
    [DISCORD_LOGIN_PROVIDER]: {
      loginProvider: DISCORD_LOGIN_PROVIDER,
      verifier: VUE_APP_DISCORD_VERIFIER,
      description: "",
      typeOfLogin: DISCORD,
      name: DISCORD,
      clientId: VUE_APP_DISCORD_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      showOnModal: true,
      mainOption: true,
      showOnDesktop: true,
      showOnMobile: true,
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_DISCORD_VERIFIER,
    } as LoginConfigItem,
    [LINE_LOGIN_PROVIDER]: {
      loginProvider: LINE_LOGIN_PROVIDER,
      verifier: VUE_APP_LINE_VERIFIER,
      description: "",
      typeOfLogin: LINE,
      name: "LINE",
      clientId: VUE_APP_LINE_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "line",
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_LINE_VERIFIER,
    } as LoginConfigItem,
    [REDDIT_LOGIN_PROVIDER]: {
      loginProvider: REDDIT_LOGIN_PROVIDER,
      verifier: VUE_APP_REDDIT_VERIFIER,
      description: "",
      typeOfLogin: JWT,
      name: REDDIT,
      clientId: VUE_APP_REDDIT_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        verifierIdField: "name",
        connection: "Reddit",
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_REDDIT_VERIFIER,
    } as LoginConfigItem,
    [APPLE_LOGIN_PROVIDER]: {
      loginProvider: APPLE_LOGIN_PROVIDER,
      verifier: VUE_APP_APPLE_VERIFIER,
      description: "",
      typeOfLogin: APPLE,
      name: APPLE,
      clientId: VUE_APP_APPLE_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "apple",
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_APPLE_VERIFIER,
    } as LoginConfigItem,
    [GITHUB_LOGIN_PROVIDER]: {
      loginProvider: GITHUB_LOGIN_PROVIDER,
      verifier: VUE_APP_GITHUB_VERIFIER,
      description: "",
      typeOfLogin: GITHUB,
      name: "GitHub",
      clientId: VUE_APP_GITHUB_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "github",
        isVerifierIdCaseSensitive: false,
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_GITHUB_VERIFIER,
    } as LoginConfigItem,
    [TWITCH_LOGIN_PROVIDER]: {
      loginProvider: TWITCH_LOGIN_PROVIDER,
      verifier: VUE_APP_TWITCH_VERIFIER,
      description: "",
      typeOfLogin: TWITCH,
      name: TWITCH,
      clientId: VUE_APP_TWITCH_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_TWITCH_VERIFIER,
    } as LoginConfigItem,
    [LINKEDIN_LOGIN_PROVIDER]: {
      loginProvider: LINKEDIN_LOGIN_PROVIDER,
      verifier: VUE_APP_LINKEDIN_VERIFIER,
      description: "",
      typeOfLogin: LINKEDIN,
      name: "LinkedIn",
      clientId: VUE_APP_LINKEDIN_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "linkedin",
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_LINKEDIN_VERIFIER,
    } as LoginConfigItem,
    [WECHAT_LOGIN_PROVIDER]: {
      loginProvider: WECHAT_LOGIN_PROVIDER,
      verifier: VUE_APP_WECHAT_VERIFIER,
      description: "",
      typeOfLogin: JWT,
      name: "WeChat",
      clientId: VUE_APP_WECHAT_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: false,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "Wechat",
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_WECHAT_VERIFIER,
    } as LoginConfigItem,
    [KAKAO_LOGIN_PROVIDER]: {
      loginProvider: KAKAO_LOGIN_PROVIDER,
      verifier: VUE_APP_KAKAO_VERIFIER,
      description: "",
      typeOfLogin: JWT,
      name: "Kakao",
      clientId: VUE_APP_KAKAO_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_LOGIN_DOMAIN,
        connection: "Kakao",
      },
      // For torus only
      buttonDescription: "",
      walletVerifier: VUE_APP_WALLET_KAKAO_VERIFIER,
    } as LoginConfigItem,
    [EMAIL_PASSWORDLESS_LOGIN_PROVIDER]: {
      loginProvider: EMAIL_PASSWORDLESS_LOGIN_PROVIDER,
      verifier: VUE_APP_HOSTED_EMAIL_PASSWORDLESS_VERIFIER,
      description: "login.verifier-email-desc",
      typeOfLogin: JWT,
      name: "email",
      clientId: VUE_APP_HOSTED_EMAIL_PASSWORDLESS_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_PASSWORDLESS_DOMAIN,
        verifierIdField: "name",
        connection: "email",
        isVerifierIdCaseSensitive: false,
      },
      // For torus only
      buttonDescription: "Sign up/in with Email",
      walletVerifier: VUE_APP_WALLET_HOSTED_EMAIL_PASSWORDLESS_VERIFIER,
    } as LoginConfigItem,
    [SMS_PASSWORDLESS_LOGIN_PROVIDER]: {
      loginProvider: SMS_PASSWORDLESS_LOGIN_PROVIDER,
      verifier: VUE_APP_HOSTED_SMS_PASSWORDLESS_VERIFIER,
      description: "login.verifier-sms-desc-2",
      typeOfLogin: JWT,
      name: "mobile",
      clientId: VUE_APP_HOSTED_EMAIL_PASSWORDLESS_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: VUE_APP_PASSWORDLESS_DOMAIN,
        verifierIdField: "name",
        connection: "sms",
        isVerifierIdCaseSensitive: false,
      },

      // for torus only.
      buttonDescription: "Sign up/in with Mobile",
      walletVerifier: VUE_APP_WALLET_HOSTED_SMS_PASSWORDLESS_VERIFIER,
    } as LoginConfigItem,
    [WEBAUTHN_LOGIN_PROVIDER]: {
      loginProvider: WEBAUTHN_LOGIN_PROVIDER,
      verifier: VUE_APP_WEBAUTHN_VERIFIER,
      description: "login.verifier-webauth-desc",
      typeOfLogin: WEBAUTHN,
      name: "biometric",
      clientId: VUE_APP_WEBAUTHN_CLIENT_ID,
      verifierSubIdentifier: VUE_APP_VERIFIER_SUBIDENTIFIER,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      mainOption: false,
      showOnModal: false,
      showOnDesktop: false,
      showOnMobile: false,
      // For torus only
      buttonDescription: "",
    } as LoginConfigItem,
  } as LoginConfig,
  loginsWithLightLogo: [APPLE, GITHUB],
};

export const openLoginVerifiers: string[] = Object.values(config.loginConfig).reduce((acc: string[], x) => {
  acc.push(x.verifier);
  if (x.walletVerifier) acc.push(x.walletVerifier);
  if (x.verifierSubIdentifier) acc.push(x.verifierSubIdentifier);
  return acc;
}, []);

export default config;
